import { useEffect } from "react"
import {
  setContactList,
  setDocumentLibraryClientFiles,
  setFilesWithTags,
  setFirstVisit,
  setFlatFileList,
  setHeadAccountId,
  setHeaderState,
  setIndustries,
  setModalOpen,
  setNotifications,
  setOpenFolderId,
  setPendingFiles,
  setPersonId,
  setProviderStatuses,
  setProviders,
  setSearchResult,
  setSearchedTag,
  setSelectedContact,
  setSelectedFile,
  setSelectedFolder,
  setSelectedIndustry,
  setSelectedProject,
  setSelectedProvider,
  setSelectedTheme,
  setSelectedUserSharingWithMe,
  setSharedWithMeFolders,
  setSharesAllContacts,
  setShowConnectivityBanner,
  setUserFolders,
  setUsersSharingWithMe,
} from "../contexts/application/action"
import { IApplicationState } from "../contexts/application/constants"
import { useApplicationContext } from "../contexts/application/context"
import { useSearchContext } from "../contexts/search"
import { setSearchExpression } from "../contexts/search/actions"
import { setCurrentUser, useUserContext } from "../contexts/users"
import { TThemeName } from "../design-tokens/types"

//helper hook for storybook and tests to set up isolated app state (use useStandardUser hook for full user setup)

export const useCustomAppState = ({
  currentUser,
  folders,
  contacts,
  usersSharingWithMe,
  sharedWithMeFolders,
  contactsShares,
  selectedFolderId,
  selectedFileId,
  selectedContactId,
  selectedSharingWithMeUserId,
  pendingFiles,
  firstVisit,
  searchResult,
  searchedTag,
  modalOpen,
  notifications,
  industries,
  providers,
  selectedProvider,
  selectedIndustry,
  showConnectivityBanner,
  providerStatuses,
  savedOpenFolderId,
  filesWithTags,
  selectedProject,
  flatFileList,
  documentLibraryClientFiles,
  selectedTheme,
  hideHeader,
  searchExpression,
  headAccountId,
  personId,
}: Partial<IApplicationState>) => {
  const { dispatch } = useApplicationContext()
  const { dispatch: dispatchUser } = useUserContext()
  const { dispatch: dispatchSearch } = useSearchContext()

  useEffect(() => {
    if (currentUser) dispatchUser(setCurrentUser(currentUser))
    if (folders) dispatch(setUserFolders(folders))
    if (contacts) dispatch(setContactList(contacts))
    if (selectedFileId) dispatch(setSelectedFile(selectedFileId))
    if (usersSharingWithMe) dispatch(setUsersSharingWithMe(usersSharingWithMe))
    if (sharedWithMeFolders)
      dispatch(setSharedWithMeFolders(sharedWithMeFolders))
    if (contactsShares) dispatch(setSharesAllContacts(contactsShares))
    if (selectedFolderId) dispatch(setSelectedFolder(selectedFolderId))
    if (selectedContactId) dispatch(setSelectedContact(selectedContactId))
    if (selectedSharingWithMeUserId)
      dispatch(setSelectedUserSharingWithMe(selectedSharingWithMeUserId))
    if (pendingFiles) dispatch(setPendingFiles({ files: pendingFiles }))
    if (firstVisit !== undefined) dispatch(setFirstVisit(firstVisit))
    if (searchResult) dispatch(setSearchResult(searchResult))
    if (searchedTag) dispatch(setSearchedTag(searchedTag))
    if (modalOpen) dispatch(setModalOpen(modalOpen))
    if (notifications) dispatch(setNotifications(notifications))
    if (showConnectivityBanner) dispatch(setShowConnectivityBanner)
    if (industries) dispatch(setIndustries(industries))
    if (providers) dispatch(setProviders(providers))
    if (selectedProvider) dispatch(setSelectedProvider(selectedProvider))
    if (selectedIndustry) dispatch(setSelectedIndustry(selectedIndustry))
    if (providerStatuses) dispatch(setProviderStatuses(providerStatuses))
    if (savedOpenFolderId) dispatch(setOpenFolderId(savedOpenFolderId))
    if (filesWithTags) dispatch(setFilesWithTags(filesWithTags))
    if (selectedProject) dispatch(setSelectedProject(selectedProject))
    if (flatFileList) dispatch(setFlatFileList(flatFileList))
    if (documentLibraryClientFiles)
      dispatch(setDocumentLibraryClientFiles(documentLibraryClientFiles))
    if (selectedTheme) dispatch(setSelectedTheme(selectedTheme as TThemeName))
    if (hideHeader) dispatch(setHeaderState(hideHeader))
    if (searchExpression) dispatchSearch(setSearchExpression(searchExpression))
    if (headAccountId) dispatch(setHeadAccountId(headAccountId))
    if (personId) dispatch(setPersonId(personId))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
