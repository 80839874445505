/* eslint-disable react-hooks/exhaustive-deps */

import "./Modal.css"

import React, { FC, ReactNode, useEffect, useRef, useState } from "react"

import classNames from "classnames"
import { createPortal } from "react-dom"
import { ThemeNames } from "../../../contexts/application/constants"

import { useApplicationContext } from "../../../contexts/application/context"
import { useDockSDK } from "../../../dock-integration/dock-context"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"

import "./Modal.css"

type ModalSubComponentProps = {
  className?: string
  children?: React.ReactNode
  hideHeaderBorderBottom?: boolean
}

export interface ModalSubHeaderProps extends ModalSubComponentProps {
  icon?: string
  children?: React.ReactNode
}

export const ModalSubHeader: FC<ModalSubHeaderProps> = ({
  className,
  children,
  icon,
}) => {
  const Icon = useGetIcon(icon)
  return (
    <h2 className={`sub-header ${className ?? ""}`}>
      {icon && <span className="icon-box">{Icon}</span>}
      {children}
    </h2>
  )
}

export const ModalHeader: FC<ModalSubComponentProps> = ({
  className,
  children,
  hideHeaderBorderBottom,
}) => {
  const headerClassName = `header ${className ?? ""}`
  const h1ClassName = classNames(headerClassName, {
    "header__border-bottom--none": hideHeaderBorderBottom,
    "header__border-bottom--base": !hideHeaderBorderBottom,
  })
  return <h1 className={h1ClassName}>{children}</h1>
}

export const ModalContent: FC<ModalSubComponentProps> = ({
  className,
  children,
}) => {
  const [hasScrollbar, setHasScrollbar] = useState(false)
  const modalContentRef = useRef<HTMLDivElement>(null)

  //need to know if scrollbar is visible to subtract the width of the scrollbar (if any) from the right padding so content is centered
  // moved from Modal (wrapper) to here (content)
  useEffect(() => {
    if (!modalContentRef.current) return
    if (
      modalContentRef.current.clientHeight <
      modalContentRef.current.scrollHeight
    )
      setHasScrollbar(true)
  }, [children])

  return (
    <div
      ref={modalContentRef}
      className={`modal-content ${hasScrollbar ? "with-scrollbar" : ""} ${
        className ?? ""
      }`}
      data-testid={className}
    >
      {children}
    </div>
  )
}

export const ModalFooter: FC<ModalSubComponentProps> = ({
  children,
  className,
}) => {
  const [footerHeight, setFooterHeight] = useState(0)
  const footerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!footerRef.current) return
    setFooterHeight(footerRef.current.clientHeight)
  }, [])
  return (
    <>
      <div className={`modal-footer ${className ?? ""}`} ref={footerRef}>
        {children}
      </div>
      <div
        className="hide-on-mobile-only extra-padding"
        style={{ paddingTop: `${footerHeight ? footerHeight - 40 : 0}px` }}
      ></div>
    </>
  )
}
export interface ModalProps {
  children: ReactNode
  hasCloseButton?: boolean
  onClose?: () => void
  className?: string
  name: string //not visible, for accessibility
  backdropClassName?: string
  hasAutoFocus?: boolean
  hasMaxWidth?: boolean
}

export const Modal: FC<ModalProps> = ({
  children,
  hasCloseButton = true,
  onClose,
  className = "",
  name,
  backdropClassName = "",
  hasAutoFocus = false,
  hasMaxWidth = false,
}) => {
  const {
    applicationState: { selectedTheme },
  } = useApplicationContext()
  const dockContext = useDockSDK()

  const CrossIcon = useGetIcon("Cross")

  // TODO: Prevent outside click events - not working, hasn't worked in the previous solution
  //Prevent outside scroll. https://dev.azure.com/secure-the-file/Application/_workitems/edit/15338
  useEffect(() => {
    // multiple abrdn themes
    const selectedThemeProcessed = selectedTheme?.includes("ABRDN")
      ? ThemeNames.ABRDN
      : selectedTheme

    switch (selectedThemeProcessed) {
      case "CO_OP":
        document.body.style.overflowY = "hidden"
        document.body.style.height = "100%"
        document.body.style.width = "100%"
        break
      case "QUILTER":
      case ThemeNames.ABRDN:
      case ThemeNames.SCHOFIELD_SWEENEY:
        // to do: prevent scroll while modal is open https://dev.azure.com/secure-the-file/Application/_workitems/edit/15338
        // seems to be either one or the other (slight move of the background or no scroll)
        // !!! only Windows users are able to see the difference in the background
        // position set to fixed to prevent scroll
        document.body.style.position = "unset"
        document.body.style.width = "100%"
        break
      default:
        document.body.style.position = "fixed"
        document.body.style.width = "100%"
    }
    return () => {
      document.body.style.position = "unset"
      document.body.style.width = ""
      document.body.style.overflow = "unset"
    }
  }, [selectedTheme])

  return createPortal(
    <>
      <div
        className={`cover-screen backdrop ${
          backdropClassName ? backdropClassName : ""
        }`}
        onClick={() => {
          if (onClose) {
            onClose()
          }
        }}
      />
      <div
        className={`cover-screen modal-wrapper ${selectedTheme ?? ""} ${
          selectedTheme?.toLowerCase() ?? ""
        } ${backdropClassName ? ` ${backdropClassName}` : ""}`}
        role="dialog"
        aria-label={name}
      >
        <div
          className={classNames("modal", className, {
            "modal--with-max-width": hasMaxWidth,
          })}
          data-testid={className ?? name ?? "modal"}
        >
          {hasCloseButton && (
            <button
              className="close-button"
              aria-label="Close"
              onClick={() => {
                if (onClose) {
                  onClose()
                }
              }}
              title="Close"
              type="button"
              autoFocus={hasAutoFocus}
            >
              <span aria-label="Close dialog" className="icon-box">
                {CrossIcon}
              </span>
            </button>
          )}
          <div className={`modal-content-wrapper`}>{children}</div>
        </div>
      </div>
    </>,
    dockContext?.domElement ?? document.body
  )
}
