import { Industry, Provider } from "legado-generated-api-client/dist/models"
import {
  DistributionProjectAnalyticsInfoDto,
  DistributionProjectInfoDto,
  FilesWithTagsDto,
  FlatFileNodeDto,
} from "legado-generated-api-client/models"
import {
  IContact,
  IFile,
  IFolder,
  INodeShare,
  INotification,
  IProviderStatus,
  IUser,
} from "../../api/api-client/api-types"

import { ApiConnection } from "../../api/api-client/common/ApiConnection"
import { TFilterOption } from "../../component/modules/Filter/Filter"
import { TThemeName } from "../../design-tokens/types"
import { TSearchResult } from "./constants"

export const REPLACE_SHARED_WITH_ME_FOLDER = "REPLACE_SHARED_WITH_ME_FOLDER"

export const replaceSharedWithMeFolder = (newFolder: IFolder) => {
  return {
    type: REPLACE_SHARED_WITH_ME_FOLDER,
    folder: newFolder,
  }
}

export const REPLACE_FOLDER = "REPLACE_FOLDER"
export const replaceFolder = (newFolder: IFolder) => {
  return {
    type: REPLACE_FOLDER,
    folder: newFolder,
  }
}

export const UPDATE_FOLDER = "UPDATE_FOLDER"

export const updateFolder = (newFolder: IFolder) => {
  return {
    type: UPDATE_FOLDER,
    folder: newFolder,
  }
}

export const SET_PARENT_SHARES = "SET_PARENT_SHARES"

export const setParentShares = (folder: IFolder) => {
  return {
    type: "SET_PARENT_SHARES",
    folder,
  }
}

export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT"

export const setSearchResult = (newResult?: TSearchResult) => {
  return {
    type: SET_SEARCH_RESULT,
    newResult,
  }
}

export const SET_SEARCHED_TAG = "SET_SEARCHED_TAG"

export const setSearchedTag = (searchedTag: string) => {
  return {
    type: SET_SEARCHED_TAG,
    searchedTag,
  }
}

export const SET_SELECTED_FILE = "SET_SELECTED_FILE"

// TODO: this should be renamed to setSelectedFileId but doing that would change many files in the code base.
export const setSelectedFile = (fileId?: string) => {
  return {
    type: SET_SELECTED_FILE,
    fileId,
  }
}

export const SET_AUTH_LOADING = "SET_AUTH_LOADING"

export const setAuthLoading = (authLoading: boolean) => {
  return {
    type: SET_AUTH_LOADING,
    authLoading,
  }
}

export const SET_LOADING_STATE = "SET_LOADING_STATE"

export const setLoadingState = (isLoading: boolean) => {
  return {
    type: SET_LOADING_STATE,
    isLoading,
  }
}

export const SET_UPLOADING_STATE = "SET_UPLOADING_STATE"

export const setUploadingState = (isUploading: boolean) => {
  return {
    type: SET_UPLOADING_STATE,
    isUploading,
  }
}

export const SET_SELECTED_FOLDER = "SET_SELECTED_FOLDER"

export const setSelectedFolder = (folderId?: string) => {
  return {
    type: SET_SELECTED_FOLDER,
    folderId,
  }
}

export const SET_SHOW_MENU = "SET_SHOW_MENU"

export const setShowMenu = (showMenu: boolean) => {
  return {
    type: SET_SHOW_MENU,
    showMenu,
  }
}

export const SET_SHOW_FOOTER = "SET_SHOW_FOOTER"

export const setShowFooter = (showFooter: boolean) => {
  return {
    type: SET_SHOW_FOOTER,
    showFooter,
  }
}

export const SET_FOLDERS = "SET_FOLDERS"

export const setUserFolders = (folders: IFolder[]) => {
  return {
    type: SET_FOLDERS,
    folders,
  }
}

export const SET_PROVIDER_STATUSES = "SET_PROVIDER_STATUSES"

export const setProviderStatuses = (providerStatuses: IProviderStatus[]) => {
  return {
    type: SET_PROVIDER_STATUSES,
    providerStatuses,
  }
}

export const UPDATE_PROVIDER_STATUS = "UPDATE_PROVIDER_STATUS"

export const updateProviderStatus = (updatedStatus: IProviderStatus) => {
  return {
    type: UPDATE_PROVIDER_STATUS,
    updatedStatus,
  }
}

export const SET_SELECTED_CONTACT = "SET_SELECTED_CONTACT"

export const setSelectedContact = (contactId: string) => {
  return {
    type: SET_SELECTED_CONTACT,
    selectedContactId: contactId,
  }
}

export const ADD_CONTACT = "ADD_CONTACT"
export const addContact = (contact: IContact) => {
  return {
    type: ADD_CONTACT,
    contact,
  }
}

export const REMOVE_CONTACT = "REMOVE_CONTACT"
export const removeContact = (deleteContactId: string) => {
  return {
    type: REMOVE_CONTACT,
    deleteContactId,
  }
}

export const SET_SELECTED_USER_SHARING_WITH_ME =
  "SET_SELECTED_USER_SHARING_WITH_ME"

export const setSelectedUserSharingWithMe = (userId: string) => {
  return {
    type: SET_SELECTED_USER_SHARING_WITH_ME,
    userId,
  }
}

export const SET_USERS_SHARING_WITH_ME = "SET_USERS_SHARING_WITH_ME"
export const setUsersSharingWithMe = (usersSharingWithMe: IUser[]) => {
  return {
    type: SET_USERS_SHARING_WITH_ME,
    usersSharingWithMe,
  }
}

export const SET_FIRST_VISIT = "SET_FIRST_VISIT"
export const setFirstVisit = (firstVisit: boolean) => {
  return {
    type: SET_FIRST_VISIT,
    firstVisit,
  }
}

export const SET_USING_STATIC_DATA = "SET_USING_STATIC_DATA"
export const setUsingStaticData = (usingStaticData: boolean) => {
  return {
    type: SET_USING_STATIC_DATA,
    usingStaticData,
  }
}

export const ADD_OR_REPLACE_CONTACTS_SHARES = "ADD_OR_REPLACE_CONTACTS_SHARES"
export const addOrReplaceContactsShares = (contactsShares: INodeShare[]) => {
  return {
    type: ADD_OR_REPLACE_CONTACTS_SHARES,
    contactsShares,
  }
}
export const SET_SHARES_ALL_CONTACTS = "SET_SHARES_ALL_CONTACTS"
export const setSharesAllContacts = (contactsShares: INodeShare[]) => {
  return {
    type: SET_SHARES_ALL_CONTACTS,
    contactsShares,
  }
}

export const SET_SHARED_WITH_ME_FOLDERS = "SET_SHARED_WITH_ME_FOLDERS"
export const setSharedWithMeFolders = (sharedFolders: IFolder[]) => {
  return {
    type: SET_SHARED_WITH_ME_FOLDERS,
    folders: sharedFolders,
  }
}

export const SET_CONTACT_LIST = "SET_CONTACT_LIST"
export const setContactList = (contactList: IContact[]) => {
  return {
    type: SET_CONTACT_LIST,
    contacts: contactList,
  }
}

export const SET_API_CONNECTION = "SET_API_CONNECTION"
export const setApiConnection = (apiConnection: ApiConnection) => {
  return {
    type: SET_API_CONNECTION,
    apiConnection,
  }
}

export const SET_SELECTED_THEME = "SET_SELECTED_THEME"
export const setSelectedTheme = (selectedTheme: TThemeName) => {
  return {
    type: SET_SELECTED_THEME,
    selectedTheme,
  }
}

export const ADD_FILES_TO_FOLDER = "ADD_FILES_TO_FOLDER"
export const addFilesToFolder = (files: IFile[]) => ({
  type: ADD_FILES_TO_FOLDER,
  files,
})

export const CHANGE_FILE_IN_FOLDER = "CHANGE_FILE_IN_FOLDER"
export const changeFileInFolder = ({
  file,
  folderId,
}: {
  file: IFile
  folderId: string
}) => ({
  type: CHANGE_FILE_IN_FOLDER,
  file,
  folderId,
})

export const DELETE_FILE_IN_FOLDER = "DELETE_FILE_IN_FOLDER"
export const deleteFileInFolder = ({
  file,
  folderId,
}: {
  file: IFile
  folderId: string
}) => ({
  type: DELETE_FILE_IN_FOLDER,
  file,
  folderId,
})

export const ADD_FILES_TO_SHARED_WITH_ME_FOLDER =
  "ADD_FILE_TO_SHARED_WITH_ME_FOLDER"
export const addFilesToSharedWithMeFolder = (files: IFile[]) => ({
  type: ADD_FILES_TO_SHARED_WITH_ME_FOLDER,
  files,
})

export const ADD_FILE_TO_SHARED_WITH_ME_FOLDER =
  "ADD_FILE_TO_SHARED_WITH_ME_FOLDER"
export const addFileToSharedWithMeFolder = ({
  file,
  folderId,
}: {
  file: IFile
  folderId: string
}) => ({
  type: ADD_FILE_TO_SHARED_WITH_ME_FOLDER,
  file,
  folderId,
})

export const CHANGE_FILE_IN_SHARED_WITH_ME_FOLDER =
  "CHANGE_FILE_IN_SHARED_WITH_ME_FOLDER"
export const changeFileInSharedWithMeFolder = ({
  file,
  folderId,
}: {
  file: IFile
  folderId: string
}) => ({
  type: CHANGE_FILE_IN_SHARED_WITH_ME_FOLDER,
  file,
  folderId,
})

export const DELETE_FILE_IN_SHARED_WITH_ME_FOLDER =
  "DELETE_FILE_IN_SHARED_WITH_ME_FOLDER"
export const deleteFileInSharedWithMeFolder = ({
  file,
  folder,
}: {
  file: IFile
  folder: IFolder
}) => ({
  type: DELETE_FILE_IN_SHARED_WITH_ME_FOLDER,
  file,
  folder,
})

export const RENAME_FOLDER_IN_SHARED_WITH_ME = "RENAME_FOLDER_IN_SHARED_WITH_ME"
export const renameFolderInSharedWithMe = ({
  folderName,
  folderId,
}: {
  folderName: string
  folderId: string
}) => ({
  type: RENAME_FOLDER_IN_SHARED_WITH_ME,
  folderName,
  folderId,
})

export const DELETE_FOLDER_IN_SHARED_WITH_ME = "DELETE_FOLDER_IN_SHARED_WITH_ME"
export const deleteFolderInSharedWithMe = ({
  folderId,
}: {
  folderId: string
}) => ({
  type: DELETE_FOLDER_IN_SHARED_WITH_ME,
  folderId,
})

export const ADD_FOLDER_IN_SHARED_WITH_ME = "ADD_FOLDER_IN_SHARED_WITH_ME"
export const addFolderInSharedWithMe = ({ folder }: { folder: IFolder }) => ({
  type: ADD_FOLDER_IN_SHARED_WITH_ME,
  folder,
})

export const ADD_PENDING_FILES = "ADD_PENDING_FILES"
export const addPendingFiles = ({ files }: { files: IFile[] }) => ({
  type: ADD_PENDING_FILES,
  files,
})

export const SET_PENDING_FILES = "SET_PENDING_FILES"
export const setPendingFiles = ({ files }: { files: IFile[] }) => ({
  type: SET_PENDING_FILES,
  files,
})

export const DELETE_FOLDER = "DELETE_FOLDER"
export const deleteFolder = ({ folderId }: { folderId: string }) => ({
  type: DELETE_FOLDER,
  folderId,
})

export const RENAME_FOLDER = "RENAME_FOLDER"
export const renameFolder = ({
  folderName,
  folderId,
}: {
  folderName: string
  folderId: string
}) => ({
  type: RENAME_FOLDER,
  folderName,
  folderId,
})

export const ADD_FOLDER = "ADD_FOLDER"
export const addFolder = ({ newFolder }: { newFolder: IFolder }) => ({
  type: ADD_FOLDER,
  newFolder,
})

export const SET_SUGGESTED_FOLDERS = "SET_SUGGESTED_FOLDERS"
export const setSuggestedFolders = ({
  suggestedFolders,
}: {
  suggestedFolders: IFolder[]
}) => ({
  type: SET_SUGGESTED_FOLDERS,
  suggestedFolders,
})

export const SET_PINNED_FOLDERS_FILES = "SET_PINNED_FOLDERS_FILES"
export const setPinnedFoldersFiles = ({
  pinnedFoldersAndFiles,
}: {
  pinnedFoldersAndFiles: IFolder
}) => ({
  type: SET_PINNED_FOLDERS_FILES,
  pinnedFoldersAndFiles,
})

export const UPDATE_PINNED_FOLDER_OR_FILE = "UPDATE_PINNED_FOLDER_OR_FILE"
export const updatePinnedFolderOrFile = ({
  pinnedFolderOrFile,
  isPinned,
}: {
  pinnedFolderOrFile: IFolder | IFile
  isPinned: boolean
}) => ({
  type: UPDATE_PINNED_FOLDER_OR_FILE,
  pinnedFolderOrFile,
  isPinned,
})

export const SET_MODAL_OPEN = "SET_MODAL_OPEN"
export const setModalOpen = (modalOpen: boolean) => {
  return {
    type: SET_MODAL_OPEN,
    modalOpen,
  }
}

export const SET_API_KEY = "SET_API_KEY"
export const setApiKey = (apiKey: string) => {
  return {
    type: SET_API_KEY,
    apiKey,
  }
}

export const SET_HOST_URL = "SET_HOST_URL"
export const setHostUrl = (hostUrl: string) => {
  return {
    type: SET_HOST_URL,
    hostUrl,
  }
}

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS"
export const setNotifications = (notifications: INotification[]) => {
  return {
    type: SET_NOTIFICATIONS,
    notifications,
  }
}

//This describes specifically if the 'Connect Provider' message should be shown
export const SET_SHOW_CONNECTIVITY_BANNER = "SET_SHOW_CONNECTIVITY_BANNER"
export const setShowConnectivityBanner = (showConnectivityBanner: boolean) => {
  return {
    type: SET_SHOW_CONNECTIVITY_BANNER,
    showConnectivityBanner,
  }
}

export const SET_PROVIDERS = "SET_PROVIDERS"
export const setProviders = (providers: Provider[]) => {
  return {
    type: SET_PROVIDERS,
    providers,
  }
}

export const SET_INDUSTRIES = "SET_INDUSTRIES"
export const setIndustries = (industries: Industry[]) => {
  return {
    type: SET_INDUSTRIES,
    industries,
  }
}

export const SET_SELECTED_INDUSTRY = "SET_SELECTED_INDUSTRY"
export const setSelectedIndustry = (selectedIndustry: Industry | undefined) => {
  return {
    type: SET_SELECTED_INDUSTRY,
    selectedIndustry,
  }
}

export const SET_SELECTED_PROVIDER = "SET_SELECTED_PROVIDER"
export const setSelectedProvider = (selectedProvider: Provider | undefined) => {
  return {
    type: SET_SELECTED_PROVIDER,
    selectedProvider,
  }
}

export const SET_GLOBAL_FILTER_OPTIONS = "SET_GLOBAL_FILTER_OPTIONS"

export const setGlobalFilterOptions = (
  globalFilterOptions: TFilterOption[]
) => {
  return {
    type: SET_GLOBAL_FILTER_OPTIONS,
    globalFilterOptions,
  }
}

export const SET_OPEN_FOLDER_ID = "SET_OPEN_FOLDER_ID"
export const setOpenFolderId = (savedOpenFolderId: string) => {
  return {
    type: SET_OPEN_FOLDER_ID,
    savedOpenFolderId,
  }
}

export const SET_FILES_WITH_TAGS = "SET_FILES_WITH_TAGS"
export const setFilesWithTags = (filesWithTags: FilesWithTagsDto[]) => {
  return {
    type: SET_FILES_WITH_TAGS,
    filesWithTags,
  }
}

export const SET_CLIENTS = "SET_CLIENTS"
export const setClients = (clients: IUser[]) => {
  return {
    type: SET_CLIENTS,
    clients,
  }
}

export const SET_PROJECTS = "SET_PROJECTS"
export const setProjects = (
  projects: DistributionProjectAnalyticsInfoDto[]
) => {
  return {
    type: SET_PROJECTS,
    projects,
  }
}

export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT"
export const setSelectedProject = (
  selectedProject: DistributionProjectInfoDto
) => {
  return {
    type: SET_SELECTED_PROJECT,
    selectedProject,
  }
}

export const SET_PROJECT_ANALYTICS = "SET_PROJECT_ANALYTICS"
export const setProjectAnalytics = (
  projectAnalytics: DistributionProjectAnalyticsInfoDto
) => {
  return {
    type: SET_PROJECT_ANALYTICS,
    projectAnalytics,
  }
}

export const SET_FLAT_FILE_LIST = "SET_FLAT_FILE_LIST"
export const setFlatFileList = (flatFileList: FlatFileNodeDto[]) => {
  return {
    type: SET_FLAT_FILE_LIST,
    flatFileList,
  }
}
export const SET_CLIENTS_FILES = "SET_CLIENTS_FILES"
export const setDocumentLibraryClientFiles = (
  documentLibraryClientFiles: IFile[]
) => {
  return {
    type: SET_CLIENTS_FILES,
    documentLibraryClientFiles,
  }
}
export const ADD_FILES_IN_FLAT_FILE_LIST = "ADD_FILES_IN_FLAT_FILE_LIST"
export const addFileInFlatFileList = (flatFiles: FlatFileNodeDto[]) => {
  return {
    type: ADD_FILES_IN_FLAT_FILE_LIST,
    flatFileList: flatFiles,
  }
}

export const DELETE_FILE_IN_FLAT_FILE_LIST = "DELETE_FILE_IN_FLAT_FILE_LIST"
export const deleteFileInFlatFileList = (fileId: string) => {
  return {
    type: DELETE_FILE_IN_FLAT_FILE_LIST,
    fileId,
  }
}
export const DELETE_FILE_IN_CLIENTS_FILE_LIST =
  "DELETE_FILE_IN_CLIENTS_FILE_LIST"
export const deleteFileInClientsFiles = (fileId: string) => {
  return {
    type: DELETE_FILE_IN_CLIENTS_FILE_LIST,
    fileId,
  }
}

export const SET_ANALYTICS_ROUTE = "SET_ANALYTICS_ROUTE"
export const setAnalyticsRoute = (analyticsRoute: boolean) => {
  return {
    type: SET_ANALYTICS_ROUTE,
    analyticsRoute,
  }
}

export const SET_HEAD_ACCOUNT_ID = "SET_HEAD_ACCOUNT_ID"
export const setHeadAccountId = (headAccountId: string) => {
  return {
    type: SET_HEAD_ACCOUNT_ID,
    headAccountId,
  }
}

export const SET_PERSON_ID = "SET_PERSON_ID"
export const setPersonId = (personId: string) => {
  return {
    type: SET_PERSON_ID,
    personId,
  }
}

export const SET_TIMEOUT_STATE = "SET_TIMEOUT_STATE"
export const setTimeoutState = (timeoutState: boolean) => {
  return {
    type: SET_TIMEOUT_STATE,
    timeoutState,
  }
}

export const SET_HIDDEN_ONE_X_HEADER = "SET_HIDDEN_ONE_X_HEADER"
export const setHeaderState = (hideHeader: boolean) => {
  return {
    type: SET_HIDDEN_ONE_X_HEADER,
    hideHeader,
  }
}

export const SET_USER_DATA_LOADING = "SET_USER_DATA_LOADING"
export const setUserDataLoading = (userDataLoading: boolean) => {
  return {
    type: SET_USER_DATA_LOADING,
    userDataLoading,
  }
}

export const SET_ORGANISATION_ID_FOR_ADVISER_SEARCH =
  "SET_ORGANISATION_ID_FOR_ADVISER_SEARCH"
export const setOrganisationIdForAdviserSearch = (
  organisationIdForAdviserSearch: string
) => {
  return {
    type: SET_ORGANISATION_ID_FOR_ADVISER_SEARCH,
    organisationIdForAdviserSearch,
  }
}
