import { IAction } from "../constants"
import { SET_UPLOAD_FILE_WIZARD_STATE } from "./actions"

export interface IUploadFileWizardState {
  documentId?: number
  stageId?: number
  caseId?: number
  requestId?: number
  workflowId?: string
  workflowVersionId?: number
  displayWizard: boolean
}

export interface IUploadFileWizardAction extends IAction {
  documentId?: number
  stageId?: number
  caseId?: number
  requestId?: number
  workflowId?: string
  workflowVersionId?: number
  displayWizard: boolean
}

export const initialState = {
  documentId: undefined,
  stageId: undefined,
  caseId: undefined,
  requestId: undefined,
  workflowId: undefined,
  workflowVersionId: undefined,
  displayWizard: false,
}

export const uploadFileWizardReducer = (
  uploadFileWizardState: IUploadFileWizardState,
  action: IUploadFileWizardAction
) => {
  switch (action.type) {
    case SET_UPLOAD_FILE_WIZARD_STATE:
      return {
        ...uploadFileWizardState,
        ...action,
      }
    default:
      return uploadFileWizardState
  }
}
