import { IUploadFileWizardState } from "./reducer"

export const SET_UPLOAD_FILE_WIZARD_STATE = "SET_UPLOAD_FILE_WIZARD_STATE"

export const setUploadFileWizardState = (
  uploadFileWizardState?: IUploadFileWizardState
) => {
  return {
    type: SET_UPLOAD_FILE_WIZARD_STATE,
    uploadFileWizardState,
  }
}
