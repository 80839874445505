import { ActivityLogEntryDto } from "legado-generated-api-client"
import { IFile, IFolder } from "../../../api/api-client/api-types"
import { flattenFolderTree } from "../../../api/api-client/folder/flattenFolderTree"
import { GetFileType } from "../../helpers/ResolveFunctions"
import { TFilterOption, FilterType } from "./Filter"
import { filterActivities, filterFiles, filterFolderTree } from "./filtering"
import dayjs from "dayjs"

export const checkIsFiltersApplied = (filterOptions: TFilterOption[]) => {
  return filterOptions.some((option) =>
    option.type === FilterType.DATE ? option.checked : !option.checked
  )
}

export const getStatusOptions = () => {
  const newStatusOptions: TFilterOption[] = [
    {
      name: "Read only",
      checked: true,
      value: "Read only",
      type: FilterType.STATUS,
    },
    {
      name: "Edit",
      checked: true,
      value: "Edit",
      type: FilterType.STATUS,
    },
  ]
  return newStatusOptions
}

export const getNameOptions = () => {
  const nameOption: TFilterOption = {
    name: "Name",
    checked: true,
    value: "",
    type: FilterType.NAME,
  }
  return [nameOption]
}

export const getNumberOfResultsForFilterOption = (
  allNodes: (ActivityLogEntryDto | IFile | IFolder)[],
  filterOption: TFilterOption,
  allOptions: TFilterOption[] = []
) => {
  if (
    filterOption.name === DATE_OPTIONS_NAMES.CUSTOMDATERANGE ||
    allNodes.length === 0
  )
    return
  let filteredResultsForOption = []
  const filtersOfDifferentType = allOptions.filter(
    (option) => option.type !== filterOption.type
  )
  const filtersToGetNumberForFilterOption = [
    ...filtersOfDifferentType,
    { ...filterOption, checked: true },
  ]
  if ((allNodes[0] as ActivityLogEntryDto).action) {
    filteredResultsForOption = filterActivities(
      allNodes as ActivityLogEntryDto[],
      filtersToGetNumberForFilterOption
    )
  }
  if ((allNodes[0] as IFile).extension) {
    filteredResultsForOption = filterFiles(
      allNodes as IFile[],
      filtersToGetNumberForFilterOption
    )
  }
  if ((allNodes[0] as IFolder).level !== undefined) {
    const flattenedTree = flattenFolderTree(allNodes as IFolder[])
    const files = flattenedTree.reduce(
      (files: IFile[], folder: IFolder) => [
        ...files,
        ...(folder.contents ?? []),
      ],
      []
    )
    const filteredFiles = filterFiles(files, filtersToGetNumberForFilterOption)
    if (filterOption.type === FilterType.FORMAT) {
      filteredResultsForOption = filteredFiles
    } else {
      //filter files separately to get total number of nodes matching criteria
      const filteredFolders = filterFolderTree(
        flattenedTree.map((folder) => ({
          ...folder,
          contents: [],
          childFolders: [],
        })),
        filtersToGetNumberForFilterOption
      )
      filteredResultsForOption = [...filteredFolders, ...filteredFiles]
    }
  }
  return filteredResultsForOption.length
}

export const getUpdatedFilterOptions = (
  filterOptions: TFilterOption[],
  allNodes: (ActivityLogEntryDto | IFile | IFolder)[],
  numberOfResultsForAppliedFilters: number
) => {
  return filterOptions.map((filterOption) => ({
    ...filterOption,
    numberOfResults: getNumberOfResultsForFilterOption(
      allNodes,
      filterOption,
      filterOptions
    ),
    ...(filterOption.name === DATE_OPTIONS_NAMES.CUSTOMDATERANGE && {
      disabled: numberOfResultsForAppliedFilters === 0,
    }),
  }))
}

export const getUniqueActivityOptions = (
  activities: ActivityLogEntryDto[],
  labelKey: keyof ActivityLogEntryDto,
  valueKey: keyof ActivityLogEntryDto,
  type: FilterType
): TFilterOption[] => {
  const uniqueOptions = activities.filter(
    (activity, index) =>
      activities.findIndex((a) => activity[valueKey] === a[valueKey]) === index
  )
  return Array.from(uniqueOptions).map(
    (activity) =>
      ({
        name: activity[labelKey],
        checked: true,
        value: activity[valueKey],
        type,
      } as TFilterOption)
  )
}

export function getFormatOptions(files: IFile[]) {
  let fileExtensions = new Set<string>()
  files.forEach((file) => {
    const type = GetFileType(file?.extension) ?? ""
    fileExtensions.add(type)
  })
  const formatOptions = Array.from(fileExtensions).map((e) => {
    return {
      name: e,
      checked: true,
      value: e,
      type: FilterType.FORMAT,
    } as TFilterOption
  })
  return formatOptions
}

export function getFormatOptionsContactDetails(folders: IFolder[]) {
  let fileExtensions = new Set<string>()
  fileExtensions = formatDeepLoop(folders, fileExtensions)
  const formatOptions = Array.from(fileExtensions).map((e) => {
    return {
      name: e,
      checked: true,
      value: e,
      type: FilterType.FORMAT,
    } as TFilterOption
  })
  return formatOptions
}

function formatDeepLoop(folders: IFolder[], fileExtensions: Set<string>) {
  folders.forEach((folder) => {
    if (folder.childFolders) {
      formatDeepLoop(folder.childFolders, fileExtensions).forEach((res) =>
        fileExtensions.add(res)
      )
    }
    folder.contents?.forEach((file) => {
      const type = GetFileType(file?.extension) ?? ""
      fileExtensions.add(type)
    })
  })
  return fileExtensions
}

export const DATE_OPTIONS_NAMES = {
  LAST30DAYS: "Last 30 days",
  LAST60DAYS: "Last 60 days",
  LAST90DAYS: "Last 90 days",
  CURRENTTAXYEAR: "Current tax year",
  LASTTAXYEAR: "Last tax year",
  CUSTOMDATERANGE: "Custom date range",
}

export const DefaultSelectedDateRange = {
  startDate: new Date(new Date().setHours(0, 0, 0, 0)),
  endDate: new Date(new Date().setHours(23, 59, 59, 0)),
  key: "selection",
}

export const getDateByYearOptions = (files: IFile[]) => {
  //Create a list of year from created date in files with each year appearing only once
  const dateOrderedFiles = files.sort((a, b) => {
    const dateA = new Date(a.created ?? new Date())
    const dateB = new Date(b.created ?? new Date())
    return dateA.getTime() - dateB.getTime()
  })
  const years = dateOrderedFiles
    .map((file) =>
      new Date(file.created ?? new Date()).getFullYear().toString()
    )
    .filter((value, index, self) => self.indexOf(value) === index)

  const dateByYearOptions = years.map((year) => {
    return {
      name: year,
      checked: false,
      value: new Date(year).getTime().toString(),
      type: FilterType.DATEYEAR,
    } as TFilterOption
  })
  return dateByYearOptions
}

export const getDateOptions = () => {
  const newDateOptions: TFilterOption[] = [
    {
      name: DATE_OPTIONS_NAMES.LAST30DAYS,
      checked: false,
      value: getEpochOfDaysAgo(30),
      type: FilterType.DATE,
    },
    {
      name: DATE_OPTIONS_NAMES.LAST60DAYS,
      checked: false,
      value: getEpochOfDaysAgo(60),
      type: FilterType.DATE,
    },
    {
      name: DATE_OPTIONS_NAMES.LAST90DAYS,
      checked: false,
      value: getEpochOfDaysAgo(90),
      type: FilterType.DATE,
    },
    {
      name: DATE_OPTIONS_NAMES.CURRENTTAXYEAR,
      checked: false,
      dateRange: {
        startDate: new Date(getFiscalYear(0) + "-04-06"),
        endDate: new Date(getFiscalYear(1) + "-04-06"),
      },
      value: getFiscalYear(0).toString(),
      type: FilterType.DATE,
    },
    {
      name: DATE_OPTIONS_NAMES.LASTTAXYEAR,
      checked: false,
      dateRange: {
        startDate: new Date(getFiscalYear(-1) + "-04-06"),
        endDate: new Date(getFiscalYear(0) + "-04-06"),
      },
      value: getFiscalYear(-1).toString(),
      type: FilterType.DATE,
    },
    {
      name: DATE_OPTIONS_NAMES.CUSTOMDATERANGE,
      checked: false,
      value: today,
      type: FilterType.DATE,
    },
  ]
  return newDateOptions
}

export function getFiscalYear(yearsFromCurrent: number) {
  const fiscalYear = new Date().getFullYear() + yearsFromCurrent
  return fiscalYear
}

export function getEpochOfDaysAgo(daysAgo: number) {
  const todaysDate = new Date().getDate()
  const previousDate = new Date(
    new Date().setDate(todaysDate - daysAgo)
  ).getTime()
  return previousDate.toString()
}

const today = new Date().toString()

export function isCustomDateSelected(options: TFilterOption[]) {
  const customDateSelected = options.filter(
    (x) => x.name === DATE_OPTIONS_NAMES.CUSTOMDATERANGE && x.checked
  )
  return customDateSelected.length > 0
}

export function getDayOfTheWeek(date: Date | string) {
  const dayOfWeek = dayjs(date).day()
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]

  return daysOfWeek[dayOfWeek]
}

export function formatDate(date: Date | undefined | string) {
  return dayjs(date).format("DD/MM/YYYY - HH:mm")
}
