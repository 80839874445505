import "./CreateContact.scss"

import React, { useEffect, useState } from "react"
import { IFile, IUser } from "../../api/api-client/api-types"
import {
  setDisplaySuccessOrErrorMessage,
  useToastContext,
} from "../../contexts/toasts"
import { TRadioOptions, getDocumentOptionsForSharing } from "../../utils/shares"

import { useApplicationContext } from "../../contexts/application/context"
import { notificationMessages } from "../../data/notificationMessages"
import { getDefaultFolders } from "../../utils/shares/getDefaultFolders"
import { Modal } from "../modules/Modal"
import { UnsavedProgress } from "../UnsavedProgress/UnsavedProgress"
import ContactSent from "./ContactSent"
import { CreateContactMainForm } from "./CreateContactMainForm"
import { emptyContactUser } from "./data"
import { Personal } from "./Personal"
import { SharingPermissionsNewContact } from "./SharingPermissionsNewContact"
import { Summary } from "./Summary"

export type TContactDetails = {
  firstName: string
  lastName: string
  email: string
  relationship: string
  note: string
  radioOptions: TRadioOptions[]
}

export enum CreateContactSteps {
  MAIN = "main",
  PERMISSIONS = "permissions",
  PERSONAL_NOTE = "personal_note",
  SUMMARY = "summary",
  INVITE_SENT = "invite_sent",
}
export interface CreateContactProps {
  closeCreateContact?: () => void
  sharee?: IUser
  testSharingOptions?: boolean
  documentFilesToShare?: IFile[]
  whatIsBeingShared?: string
  errorPosition?: "below" | "above"
  shouldShowErrorsOnSubmitOnly?: boolean
  isAddingContactThroughWorkflowDocument?: boolean
}

export const CreateContact: React.FC<CreateContactProps> = ({
  closeCreateContact,
  sharee,
  testSharingOptions,
  documentFilesToShare,
  whatIsBeingShared,
  errorPosition = "below",
  shouldShowErrorsOnSubmitOnly = false,
  isAddingContactThroughWorkflowDocument = false,
}) => {
  const {
    applicationState: { folders },
  } = useApplicationContext()
  const { dispatch: toastDispatch } = useToastContext()

  const [contact, setContact] = useState({
    ...emptyContactUser,
    ...(sharee && {
      firstName: sharee.firstName ?? "",
      surname: sharee.surname ?? "",
      email: sharee.email ?? "",
      phoneNumber: sharee.phoneNumber ?? "",
      countryCode: sharee.countryCode ?? "",
    }),
  })
  const [sharingOptions, setSharingOptions] = useState([] as TRadioOptions[])
  const [step, setStep] = useState(CreateContactSteps.MAIN)
  const [unsavedProgressOpen, setUnsavedProgressOpen] = useState(false)

  useEffect(() => {
    if (documentFilesToShare) {
      setSharingOptions(
        getDocumentOptionsForSharing(
          documentFilesToShare,
          isAddingContactThroughWorkflowDocument
        )
      )
    } else {
      setSharingOptions(getDefaultFolders(folders))
    }
  }, [isAddingContactThroughWorkflowDocument, documentFilesToShare, folders])

  const resetForm = () => {
    if (closeCreateContact) {
      closeCreateContact()
    }
    setStep(CreateContactSteps.MAIN)
    setContact(emptyContactUser)
    setSharingOptions(getDefaultFolders(folders))
  }

  const closeModal = () => {
    let hasUnsavedProgress = false
    if (contact) {
      if (
        contact.firstName! !== "" ||
        contact.surname! !== "" ||
        contact.email! !== "" ||
        contact.phoneNumber! !== "" ||
        contact.relationship !== "" ||
        (contact.surname && contact.firstName && contact.email)
      )
        hasUnsavedProgress = true
    }
    if (unsavedProgressOpen) {
      setUnsavedProgressOpen(false)
      resetForm()
    } else {
      if (
        step === CreateContactSteps.INVITE_SENT ||
        (step !== CreateContactSteps.PERMISSIONS && !hasUnsavedProgress)
      ) {
        resetForm()
      } else {
        setUnsavedProgressOpen(true)
      }
    }
  }

  return (
    <div className="create-contact" data-testid="create-contact-form">
      <Modal
        onClose={closeModal}
        name="Create contact"
        hasCloseButton={step !== CreateContactSteps.INVITE_SENT}
        className="create-contact-modal"
      >
        {unsavedProgressOpen ? (
          <UnsavedProgress
            onExit={resetForm}
            onReturn={() => setUnsavedProgressOpen(false)}
            name="Create Contact"
          />
        ) : (
          <>
            {step === CreateContactSteps.MAIN && (
              <CreateContactMainForm
                addingSharee={!!sharee}
                contact={contact}
                setContact={setContact}
                onClose={closeModal}
                onContinueButtonClick={() => {
                  if (sharingOptions.length === 0 && !testSharingOptions) {
                    setStep(
                      sharee
                        ? CreateContactSteps.SUMMARY
                        : CreateContactSteps.PERSONAL_NOTE
                    )
                  } else {
                    setStep(CreateContactSteps.PERMISSIONS)
                  }
                }}
                sharingOptions={sharingOptions}
                testSharingOptions={testSharingOptions}
                errorPosition={errorPosition}
                shouldShowErrorsOnSubmitOnly={shouldShowErrorsOnSubmitOnly}
              />
            )}
            {step === CreateContactSteps.PERMISSIONS && (
              <SharingPermissionsNewContact
                addingSharee={!!sharee}
                sharingOptions={sharingOptions}
                setSharingOptions={setSharingOptions}
                contact={contact}
                onBackButtonClick={() => {
                  setStep(CreateContactSteps.MAIN)
                }}
                onContinueButtonClick={() => {
                  setStep(
                    sharee
                      ? CreateContactSteps.SUMMARY
                      : CreateContactSteps.PERSONAL_NOTE
                  )
                }}
                showImportedFolderWarning={() => {
                  toastDispatch(
                    setDisplaySuccessOrErrorMessage({
                      message:
                        notificationMessages.editImportedFolderPermissions
                          .WARNING,
                      messageType: "WARNING",
                    })
                  )
                }}
                whatIsBeingShared={whatIsBeingShared}
                nextStepButtonLabel={
                  documentFilesToShare ? "Update" : undefined
                }
                isAddingContactThroughWorkflowDocument={
                  isAddingContactThroughWorkflowDocument
                }
              />
            )}
            {step === CreateContactSteps.PERSONAL_NOTE && (
              <Personal
                addingSharee={!!sharee}
                contact={contact!}
                setContact={setContact}
                onBackButtonClick={() => {
                  if (sharingOptions.length === 0 && !testSharingOptions) {
                    setStep(CreateContactSteps.MAIN)
                  } else {
                    setStep(
                      sharee
                        ? CreateContactSteps.SUMMARY
                        : CreateContactSteps.PERMISSIONS
                    )
                  }
                }}
                onContinueButtonClick={() => {
                  setStep(CreateContactSteps.SUMMARY)
                }}
                sharingOptions={sharingOptions}
              />
            )}
            {step === CreateContactSteps.SUMMARY && (
              <Summary
                addingSharee={!!sharee}
                contact={contact}
                sharingOptions={sharingOptions}
                setStep={setStep}
                onClose={closeCreateContact}
              />
            )}
            {step === CreateContactSteps.INVITE_SENT && (
              <ContactSent
                contact={contact}
                onClose={resetForm}
                addingSharee={!!sharee}
              />
            )}
          </>
        )}
      </Modal>
    </div>
  )
}

export default CreateContact
