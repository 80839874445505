import { NavigateFunction } from "react-router-dom"
import { PAGE_PATH } from "../../../contexts/application/constants"
import { LocalStorageKeys } from "../../../contexts/auth/localStorageKeys"
import { LogoutIcon } from "../../../design-tokens/icons/theme/coop/LogoutIcon"
import { replaceParamsInPath } from "../../../utils/navigation/replaceParamsInPath"
import { LinkProps } from "./LinksCollection"

export const coopLinksPrimaryUser = ({
  workflowId,
  workFlowVersion,
  caseId,
  navigate,
  isMenuBarExpanded,
  isDesktop,
  newNotifications,
}: {
  workflowId: string
  workFlowVersion: string
  caseId: string
  navigate: NavigateFunction
  isMenuBarExpanded?: boolean
  isDesktop?: boolean
  newNotifications?: number
}): LinkProps[] => {
  const routeToHome = () => {
    const selectedCaseId = localStorage.getItem(LocalStorageKeys.SelectedCaseId)

    navigate(
      replaceParamsInPath({
        path: PAGE_PATH.CaseDetailsPage,
        pathParameters: {
          workflowId: workflowId,
          workflowVersion: workFlowVersion,
          caseId: selectedCaseId ?? caseId,
        },
      })
    )
  }
  const sharedWithMeLink: LinkProps = {
    id: "shared-with-me",
    displayName: "Shared with me",
    onClick: () =>
      navigate(
        replaceParamsInPath({
          path: PAGE_PATH.CaseSharedWithMePage,
          pathParameters: {
            workflowId: workflowId,
            workflowVersion: workFlowVersion,
            caseId,
          },
        })
      ),
  }

  const defaultLinks: LinkProps[] = [
    {
      id: "home",
      displayName: "Home",
      onClick: routeToHome,
    },
    {
      id: "sharing",
      displayName: "Sharing",
      onClick: () =>
        navigate(
          replaceParamsInPath({
            path: PAGE_PATH.CaseSharingPage,
            pathParameters: {
              workflowId: workflowId,
              workflowVersion: workFlowVersion,
              caseId,
            },
          })
        ),
    },
    {
      id: "documents",
      displayName: "My documents",
      onClick: () =>
        navigate(
          replaceParamsInPath({
            path: PAGE_PATH.CaseDocuments,
            pathParameters: {
              workflowId: workflowId,
              workflowVersion: workFlowVersion,
              caseId,
            },
          })
        ),
    },
  ]
  const hasFilesSharedWith = localStorage.getItem(
    LocalStorageKeys.HasFilesSharedWith
  )
  if (hasFilesSharedWith) {
    defaultLinks.splice(2, 0, sharedWithMeLink)
  }

  const hasMultipleCases = localStorage.getItem(
    LocalStorageKeys.HasMultipleCases
  )

  if (hasMultipleCases) {
    localStorage.removeItem(LocalStorageKeys.SelectedCaseId)

    defaultLinks.push({
      id: "change-cases",
      displayName: "Change case",
      onClick: () => navigate(PAGE_PATH.Cases),
    })
  }
  if (isMenuBarExpanded && !isDesktop) {
    defaultLinks.push({
      id: "notifications",
      displayName: "Notifications",
      newNotificationValue: newNotifications,
      onClick: () =>
        navigate(
          replaceParamsInPath({
            path: PAGE_PATH.CaseNotificationsPage,
            pathParameters: {
              workflowId: workflowId,
              workflowVersion: workFlowVersion,
              caseId,
            },
          })
        ),
    })

    defaultLinks.push({
      id: "sign-out",
      displayName: "Sign out",
      icon: <LogoutIcon />,
      onClick: () => {
        navigate(PAGE_PATH.Logout)
      },
    })
  }

  return defaultLinks
}

export const coopLinksCaseHandler = ({
  externalId,
  workFlowVersion,
  workflowId,
  navigate,
}: {
  externalId: string
  workFlowVersion: string
  workflowId: string
  user: string
  navigate: NavigateFunction
}): LinkProps[] => {
  const caseManagerPageLinks: LinkProps[] = []

  if (externalId) {
    caseManagerPageLinks.push({
      id: "case-page",
      displayName: "Case: " + (externalId ?? ""),
      onClick: () => navigate(PAGE_PATH.CaseManagerHomePage),
    })
  }
  caseManagerPageLinks.push({
    id: "analytics",
    displayName: "Analytics",
    onClick: () =>
      navigate(
        replaceParamsInPath({
          path: PAGE_PATH.CaseManagerAnalyticsPage,
          pathParameters: {
            workflowId: workflowId,
            workflowVersion: workFlowVersion,
          },
        })
      ),
  })

  return caseManagerPageLinks
}

export const coopLinksContactUser = ({
  navigate,
  isDesktop,
}: {
  navigate: NavigateFunction
  isDesktop: boolean
}): LinkProps[] => {
  const caseContactPageLinks: LinkProps[] = [
    {
      id: "shared-with-me",
      displayName: "Shared with me",
      onClick: () => navigate(PAGE_PATH.CaseContactSharedWithMePage),
    },
  ]

  if (!isDesktop) {
    caseContactPageLinks.push({
      id: "sign-out",
      displayName: "Sign out",
      icon: <LogoutIcon />,
      onClick: () => navigate(PAGE_PATH.Logout),
    })
  }

  return caseContactPageLinks
}
