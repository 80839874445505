"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdviserApi = exports.AdviserApiFactory = exports.AdviserApiFp = exports.AdviserApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * AdviserApi - axios parameter creator
 * @export
 */
const AdviserApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Returns a list of files for a specific account
         * @summary Get File List For a specific Account
         * @param {string} accountId The ID of the account to retrieve the file list from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserAccountAccountIdGet: (accountId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new base_1.RequiredError("accountId", "Required parameter accountId was null or undefined when calling apiAdviserAccountAccountIdGet.");
            }
            const localVarPath = `/api/Adviser/account/{accountId}`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Remove the link between an adviser/professional and a user.
         * @summary Remove Client
         * @param {string} clientId The client&#x27;s user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserClientClientIdDelete: (clientId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new base_1.RequiredError("clientId", "Required parameter clientId was null or undefined when calling apiAdviserClientClientIdDelete.");
            }
            const localVarPath = `/api/Adviser/client/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Add client for Advisers.If the client already exists they are linked to the adviser/professional otherwise the user is created and then linked.  A new user will recieve an email invite which they need to accept to complete the process.
         * @summary Add client for Advisers.
         * @param {ClientInviteBody} [body] An object containing the details of the client to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserClientInvitePost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Adviser/client/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Returns a list of all the users who are linked with the advisers organisation(s).
         * @summary Get Clients List For Advisers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserClientsListGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Adviser/clients/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieves a list of all folders + files from the client's vault
         * @summary Get a Client's File List
         * @param {string} sharerId Guid ID of the client from which to retrieve the files.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserClientsSharerIdGet: (sharerId, includeDocumentRequestFiles, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sharerId' is not null or undefined
            if (sharerId === null || sharerId === undefined) {
                throw new base_1.RequiredError("sharerId", "Required parameter sharerId was null or undefined when calling apiAdviserClientsSharerIdGet.");
            }
            const localVarPath = `/api/Adviser/clients/{sharerId}`.replace(`{${"sharerId"}}`, encodeURIComponent(String(sharerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (includeDocumentRequestFiles !== undefined) {
                localVarQueryParameter["includeDocumentRequestFiles"] =
                    includeDocumentRequestFiles;
            }
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieves a list of adviser account linked to the specified organisation.
         * @summary Get Advisers for an organisation
         * @param {string} organisationId Guid Id of the organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserOrganisationOrganisationIdAdvisersGet: (organisationId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'organisationId' is not null or undefined
            if (organisationId === null || organisationId === undefined) {
                throw new base_1.RequiredError("organisationId", "Required parameter organisationId was null or undefined when calling apiAdviserOrganisationOrganisationIdAdvisersGet.");
            }
            const localVarPath = `/api/Adviser/organisation/{organisationId}/advisers`.replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.AdviserApiAxiosParamCreator = AdviserApiAxiosParamCreator;
/**
 * AdviserApi - functional programming interface
 * @export
 */
const AdviserApiFp = function (configuration) {
    return {
        /**
         * Returns a list of files for a specific account
         * @summary Get File List For a specific Account
         * @param {string} accountId The ID of the account to retrieve the file list from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserAccountAccountIdGet(accountId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AdviserApiAxiosParamCreator)(configuration).apiAdviserAccountAccountIdGet(accountId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Remove the link between an adviser/professional and a user.
         * @summary Remove Client
         * @param {string} clientId The client&#x27;s user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserClientClientIdDelete(clientId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AdviserApiAxiosParamCreator)(configuration).apiAdviserClientClientIdDelete(clientId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Add client for Advisers.If the client already exists they are linked to the adviser/professional otherwise the user is created and then linked.  A new user will recieve an email invite which they need to accept to complete the process.
         * @summary Add client for Advisers.
         * @param {ClientInviteBody} [body] An object containing the details of the client to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserClientInvitePost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AdviserApiAxiosParamCreator)(configuration).apiAdviserClientInvitePost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Returns a list of all the users who are linked with the advisers organisation(s).
         * @summary Get Clients List For Advisers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserClientsListGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AdviserApiAxiosParamCreator)(configuration).apiAdviserClientsListGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Retrieves a list of all folders + files from the client's vault
         * @summary Get a Client's File List
         * @param {string} sharerId Guid ID of the client from which to retrieve the files.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserClientsSharerIdGet(sharerId, includeDocumentRequestFiles, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AdviserApiAxiosParamCreator)(configuration).apiAdviserClientsSharerIdGet(sharerId, includeDocumentRequestFiles, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Retrieves a list of adviser account linked to the specified organisation.
         * @summary Get Advisers for an organisation
         * @param {string} organisationId Guid Id of the organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserOrganisationOrganisationIdAdvisersGet(organisationId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AdviserApiAxiosParamCreator)(configuration).apiAdviserOrganisationOrganisationIdAdvisersGet(organisationId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.AdviserApiFp = AdviserApiFp;
/**
 * AdviserApi - factory interface
 * @export
 */
const AdviserApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Returns a list of files for a specific account
         * @summary Get File List For a specific Account
         * @param {string} accountId The ID of the account to retrieve the file list from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserAccountAccountIdGet(accountId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AdviserApiFp)(configuration)
                    .apiAdviserAccountAccountIdGet(accountId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Remove the link between an adviser/professional and a user.
         * @summary Remove Client
         * @param {string} clientId The client&#x27;s user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserClientClientIdDelete(clientId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AdviserApiFp)(configuration)
                    .apiAdviserClientClientIdDelete(clientId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Add client for Advisers.If the client already exists they are linked to the adviser/professional otherwise the user is created and then linked.  A new user will recieve an email invite which they need to accept to complete the process.
         * @summary Add client for Advisers.
         * @param {ClientInviteBody} [body] An object containing the details of the client to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserClientInvitePost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AdviserApiFp)(configuration)
                    .apiAdviserClientInvitePost(body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Returns a list of all the users who are linked with the advisers organisation(s).
         * @summary Get Clients List For Advisers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserClientsListGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AdviserApiFp)(configuration)
                    .apiAdviserClientsListGet(options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Retrieves a list of all folders + files from the client's vault
         * @summary Get a Client's File List
         * @param {string} sharerId Guid ID of the client from which to retrieve the files.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserClientsSharerIdGet(sharerId, includeDocumentRequestFiles, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AdviserApiFp)(configuration)
                    .apiAdviserClientsSharerIdGet(sharerId, includeDocumentRequestFiles, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Retrieves a list of adviser account linked to the specified organisation.
         * @summary Get Advisers for an organisation
         * @param {string} organisationId Guid Id of the organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdviserOrganisationOrganisationIdAdvisersGet(organisationId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AdviserApiFp)(configuration)
                    .apiAdviserOrganisationOrganisationIdAdvisersGet(organisationId, options)
                    .then((request) => request(axios, basePath));
            });
        },
    };
};
exports.AdviserApiFactory = AdviserApiFactory;
/**
 * AdviserApi - object-oriented interface
 * @export
 * @class AdviserApi
 * @extends {BaseAPI}
 */
class AdviserApi extends base_1.BaseAPI {
    /**
     * Returns a list of files for a specific account
     * @summary Get File List For a specific Account
     * @param {string} accountId The ID of the account to retrieve the file list from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdviserApi
     */
    apiAdviserAccountAccountIdGet(accountId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AdviserApiFp)(this.configuration)
                .apiAdviserAccountAccountIdGet(accountId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Remove the link between an adviser/professional and a user.
     * @summary Remove Client
     * @param {string} clientId The client&#x27;s user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdviserApi
     */
    apiAdviserClientClientIdDelete(clientId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AdviserApiFp)(this.configuration)
                .apiAdviserClientClientIdDelete(clientId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Add client for Advisers.If the client already exists they are linked to the adviser/professional otherwise the user is created and then linked.  A new user will recieve an email invite which they need to accept to complete the process.
     * @summary Add client for Advisers.
     * @param {ClientInviteBody} [body] An object containing the details of the client to add
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdviserApi
     */
    apiAdviserClientInvitePost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AdviserApiFp)(this.configuration)
                .apiAdviserClientInvitePost(body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Returns a list of all the users who are linked with the advisers organisation(s).
     * @summary Get Clients List For Advisers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdviserApi
     */
    apiAdviserClientsListGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AdviserApiFp)(this.configuration)
                .apiAdviserClientsListGet(options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Retrieves a list of all folders + files from the client's vault
     * @summary Get a Client's File List
     * @param {string} sharerId Guid ID of the client from which to retrieve the files.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdviserApi
     */
    apiAdviserClientsSharerIdGet(sharerId, includeDocumentRequestFiles, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AdviserApiFp)(this.configuration)
                .apiAdviserClientsSharerIdGet(sharerId, includeDocumentRequestFiles, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Retrieves a list of adviser account linked to the specified organisation.
     * @summary Get Advisers for an organisation
     * @param {string} organisationId Guid Id of the organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdviserApi
     */
    apiAdviserOrganisationOrganisationIdAdvisersGet(organisationId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AdviserApiFp)(this.configuration)
                .apiAdviserOrganisationOrganisationIdAdvisersGet(organisationId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.AdviserApi = AdviserApi;
