import { ReactElement } from "react"
import MessageInfo from "./MessageInfo"
import NotificationSuccess from "./NotificationSuccess"
import { WelcomeLogo } from "./WelcomeLogo"

export const GetIcon = {
  SCHOFIELD_SWEENEYWelcomeLogo: <WelcomeLogo />,
  MessageInfo: <MessageInfo />,
  NotificationSuccess: <NotificationSuccess />,
} as { [key: string]: ReactElement }

export default GetIcon
