import { LoaderCenteredOnTop } from "../atoms/Loader"
import { useApplicationContext } from "../../contexts/application/context"

export const LoadingFallBack = () => {
  const {
    applicationState: { userDataLoading },
  } = useApplicationContext()

  return (
    <div className="loading-fallback">
      <div className="loading-fallback__spinner">
        {userDataLoading ? (
          <LoaderCenteredOnTop
            color=" #E8E8E8"
            secondaryColor="#48475C"
            backgroundColor="#7A7A82"
          />
        ) : (
          <LoaderCenteredOnTop />
        )}
      </div>
    </div>
  )
}
