import classNames from "classnames"
import { ChangeEvent, FC, KeyboardEvent, useState } from "react"
import {
  setSearchExpression,
  useSearchContext,
} from "../../../../contexts/search"
import Search from "../../../../design-tokens/icons/theme/oneX/Search"
import { useGetIcon } from "../../../../styled-components/GetIconLibraryInTheme"
import "./PageContentSearchBar.scss"

export interface PageContentSearchBarProps {
  onSubmit: (searchExpression?: string) => void
  onClearSearch?: () => void
  enableSearchContext?: boolean
  label: string
  className?: string
}

const PageContentSearchBar: FC<PageContentSearchBarProps> = ({
  onSubmit,
  onClearSearch,
  enableSearchContext = false,
  label,
  className,
}) => {
  const {
    searchState: { searchExpression },
    dispatch,
  } = useSearchContext()
  const [search, setSearch] = useState("")
  const crossIcon = useGetIcon("Cross")
  const pageContentSearchClass = classNames("page-content-search-bar", {
    [`${className}`]: className,
  })

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSubmit(search)
    }
  }

  return (
    <div className={pageContentSearchClass}>
      <input
        type="text"
        placeholder={label}
        className="search-input"
        aria-label={label}
        role="searchbox"
        value={enableSearchContext ? searchExpression : search}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (enableSearchContext) {
            dispatch(setSearchExpression(e.target.value))
          } else {
            setSearch(e.target.value)
          }
        }}
        onKeyDown={handleKeyDown}
        autoFocus
      />
      {(searchExpression || search) && (
        <button
          className="crossIcon"
          title="Clear search"
          aria-label="Clear search"
          type="button"
          tabIndex={0}
          onClick={() => {
            if (enableSearchContext) {
              dispatch(setSearchExpression(""))
            } else {
              setSearch("")
            }
            if (onClearSearch) {
              onClearSearch()
            }
          }}
        >
          {crossIcon}
        </button>
      )}
      <button
        className="search-bar-button"
        aria-label="Search document button"
        tabIndex={-1}
        onClick={() => onSubmit(search)}
      >
        <Search />
      </button>
    </div>
  )
}

export default PageContentSearchBar
