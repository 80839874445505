import classNames from "classnames"
import React, { Dispatch, SetStateAction, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { INotification } from "../../../api/api-client/api-types"
import { CaseDocument } from "../../../api/lib/workflow/models/GetCaseDocumentResponse"
import { LeftNavigationBar } from "../../../component/LeftNavigationBar/LeftNavigationBar"
import {
  coopLinksCaseHandler,
  coopLinksContactUser,
  coopLinksPrimaryUser,
} from "../../../component/LeftNavigationBar/LinksCollection/coopLinks"
import { LogoWithTextItems } from "../../../component/LeftNavigationBar/LogoWithTextItems/LogoWithTextItems"
import { CoopWelcomeText } from "../../../component/LeftNavigationBar/LogoWithTextItems/coopWelcomeText"
import { TopBar } from "../../../component/atoms/TopBar/TopBar"
import { useApplicationContext } from "../../../contexts/application/context"
import { LocalStorageKeys } from "../../../contexts/auth/localStorageKeys"
import { useSearchContext } from "../../../contexts/search"
import { ExternalLinkIcon } from "../../../design-tokens/icons/theme/coop/ExternalLink"
import FnzDownload from "../../../design-tokens/icons/theme/oneX/FnzDownload"
import CoopLogo from "../../../design-tokens/imgs/coop/CoopLogo"
import { useLeftNavMenu } from "../../../hooks/useLeftNavMenu"
import { getIsCurrentStageAllowingUploads } from "../../../utils/helpers"
import { LinksCollection } from "../../LeftNavigationBar/LinksCollection/LinksCollection"
import { LogoutLink } from "../../LogoutLink/LogoutLink"
import { NotificationButton } from "../../NotificationIcon/NotificationButton"
import { BUTTON_VARIANT, Button } from "../../atoms/Button"
import { ProfileButton } from "../../atoms/ProfileButton/ProfileButton"
import { TopBarButtonsCollection } from "../../atoms/TopBar/TopBarButtonsCollection/TopBarButtonsCollection"
import { UploadFileToCaseModalWizard } from "../../modules/UploadFileToCaseModalWizard/UploadFileToCaseModalWizard"
import { LoadingFallBack } from "../../organism/LoadingFallBack"
import PageContentSearchBar from "./Components/PageContentSearchBar"

import { toNumber } from "lodash"
import { PAGE_PATH } from "../../../contexts/application/constants"
import {
  SET_UPLOAD_FILE_WIZARD_STATE,
  useUploadFileWizardContext,
} from "../../../contexts/uploadFileWizard"
import { replaceParamsInPath } from "../../../utils/navigation/replaceParamsInPath"
import "./PageContentContainer.scss"

export enum PageContentContainerVariant {
  WelcomePage = "welcome-page",
  PersonalRepresentativePage = "personal-representative-page",
  CaseHandlerPage = "case-handler-page",
  ContactUser = "contact-user",
}

interface PageContentContainerProps {
  children: React.ReactNode
  className?: string
  pageVariant: PageContentContainerVariant
  updatePageFunction?: () => Promise<void>
  setNewlyUploadedDocuments?: Dispatch<SetStateAction<CaseDocument[]>>
}

export const PageContentContainer: React.FC<PageContentContainerProps> = ({
  children,
  className,
  pageVariant,
  updatePageFunction,
  setNewlyUploadedDocuments,
}) => {
  const {
    applicationState: { notifications, authLoading },
  } = useApplicationContext()
  const { uploadFileWizardState, dispatch: uploadFileWizardDispatch } =
    useUploadFileWizardContext()
  const {
    searchState: { searchExpression },
  } = useSearchContext()

  const navigate = useNavigate()
  const { workflowId, workflowVersion, caseId, externalId, user } = useParams()

  const { isLeftNavShowing, setIsLeftNavShowing, isDesktop } = useLeftNavMenu()
  const [showFileUploadModalWizard, setShowFileUploadModalWizard] =
    useState(false)
  const [showSearchBarForTabletOrMobile, setShowSearchBarForTabletOrMobile] =
    useState(false)

  const PageContentContainerClass = classNames("page-container", className)

  const shouldDisplayUploadButton = getIsCurrentStageAllowingUploads()

  const handleOnSearchBarClick = () => {
    if (
      !searchExpression ||
      searchExpression.trim() === "" ||
      !workflowId ||
      !workflowVersion ||
      !caseId
    )
      return
    navigate(
      replaceParamsInPath({
        path: PAGE_PATH.CaseSearchPage,
        pathParameters: {
          workflowId: workflowId,
          workflowVersion: workflowVersion,
          caseId: caseId,
        },
      })
    )
  }

  const navigateToCaseDetailsPage = () => {
    if (workflowId && workflowVersion && caseId) {
      navigate(
        replaceParamsInPath({
          path: PAGE_PATH.CaseDetailsPage,
          pathParameters: {
            workflowId: workflowId,
            workflowVersion: workflowVersion,
            caseId: caseId,
          },
        })
      )
    }
  }

  function getNewNotificationsCount({
    notifications,
    caseId,
  }: {
    notifications: INotification[]
    caseId?: string
  }) {
    let count = 0
    // user could be part of multiple cases therefore we need to filter the notifications per case
    if (caseId) {
      count = notifications.filter(
        (notification) =>
          notification.viewed === false &&
          notification.caseData?.caseId.toString() === caseId
      ).length
    } else {
      count = notifications.filter(
        (notification) => notification.viewed === false
      ).length
    }

    return count
  }

  const mewNotificationsCount =
    notifications && notifications.length > 0
      ? getNewNotificationsCount({
          notifications: notifications,
          caseId: caseId,
        })
      : 0
  // This was added as a workaround to fix the issue with flickering when starting or refreshing the application.
  if (authLoading) {
    return <LoadingFallBack />
  }

  switch (pageVariant) {
    case PageContentContainerVariant.WelcomePage:
    default:
      return (
        <div className={PageContentContainerClass}>
          {isDesktop && (
            <LeftNavigationBar
              className="expanded"
              isDesktop={isDesktop}
              setIsMenuBarExpanded={setIsLeftNavShowing}
              isMenuBarExpanded={isLeftNavShowing}
            >
              <LogoWithTextItems
                logo={<CoopLogo />}
                headerText="Welcome"
                contentText={CoopWelcomeText}
              />
            </LeftNavigationBar>
          )}
          {!isDesktop && (
            <TopBar>
              <CoopLogo />
            </TopBar>
          )}
          <div className="page-container__right-content--welcome">
            {children}
            <div className="page-container__bottom-logo">
              <CoopLogo isPrimaryColor={false} />
            </div>
          </div>
        </div>
      )

    case PageContentContainerVariant.PersonalRepresentativePage:
      return (
        <div className={PageContentContainerClass}>
          {showFileUploadModalWizard ||
            (uploadFileWizardState.displayWizard && (
              <UploadFileToCaseModalWizard
                workflowId={uploadFileWizardState.workflowId ?? ""}
                workflowVersion={
                  uploadFileWizardState.workflowVersionId?.toString() ?? ""
                }
                caseId={uploadFileWizardState.caseId?.toString() ?? ""}
                onClose={() =>
                  uploadFileWizardDispatch({
                    type: SET_UPLOAD_FILE_WIZARD_STATE,
                    displayWizard: false,
                  })
                }
                onUploadComplete={updatePageFunction}
                setNewlyUploadedDocuments={setNewlyUploadedDocuments}
              />
            ))}
          {(isDesktop || isLeftNavShowing) && (
            <div className={PageContentContainerClass}>
              <LeftNavigationBar
                isMenuBarExpanded={isLeftNavShowing}
                setIsMenuBarExpanded={setIsLeftNavShowing}
                isDesktop={isDesktop}
                leftNavRole="navigation"
              >
                <LinksCollection
                  links={coopLinksPrimaryUser({
                    workflowId: workflowId ?? "",
                    workFlowVersion: workflowVersion ?? "",
                    caseId: caseId ?? "",
                    navigate,
                    isMenuBarExpanded: isLeftNavShowing,
                    isDesktop: isDesktop,
                    newNotifications: mewNotificationsCount,
                  })}
                >
                  <>
                    <CoopLogo />
                    <div className="link-container link-container--no-fill link-container--fixed-bottom">
                      <ExternalLinkIcon />
                      <a
                        href="https://forms.office.com/Pages/ResponsePage.aspx?id=tLdPg01i6E2pdy1GrZeb2X-iYziq7fRDqO7P16H1NelUOVBIWkxRWjVGWDU4Q0tGMU41NlUwS1ZIRC4u"
                        target="_blank"
                        rel="noreferrer"
                        className="link"
                        aria-label="Navigate to Share feedback"
                      >
                        Share feedback
                      </a>
                    </div>
                  </>
                </LinksCollection>
              </LeftNavigationBar>
            </div>
          )}
          {!isDesktop && (
            <>
              <TopBar>
                <TopBarButtonsCollection
                  Logo={<CoopLogo />}
                  onSearchClick={() =>
                    setShowSearchBarForTabletOrMobile((prev) => !prev)
                  }
                  onHamburgerClick={() => setIsLeftNavShowing(true)}
                />
              </TopBar>
              {(showSearchBarForTabletOrMobile || !!searchExpression) && (
                <div className="page-container__main-menu-content-header mobile-tablet-only">
                  <PageContentSearchBar
                    className="page-container__main-menu--search-bar"
                    label="Search document"
                    onSubmit={handleOnSearchBarClick}
                    onClearSearch={navigateToCaseDetailsPage}
                    enableSearchContext
                  />
                </div>
              )}
            </>
          )}
          <div className="page-container__right-content">
            <div className="page-container__main-menu">
              {isDesktop && (
                <div>
                  <div className="page-container__main-menu-content-header hide-on-tablet-and-down">
                    <PageContentSearchBar
                      className="page-container__main-menu--search-bar"
                      label="Search document"
                      onSubmit={handleOnSearchBarClick}
                      onClearSearch={navigateToCaseDetailsPage}
                      enableSearchContext
                    />
                    {shouldDisplayUploadButton && (
                      <Button
                        variant={BUTTON_VARIANT.PRIMARY}
                        onClick={() =>
                          uploadFileWizardDispatch({
                            type: SET_UPLOAD_FILE_WIZARD_STATE,
                            displayWizard: true,
                            caseId: toNumber(caseId ?? ""),
                            workflowId: workflowId ?? "",
                            workflowVersionId: toNumber(workflowVersion ?? ""),
                          })
                        }
                      >
                        <FnzDownload />
                        Upload new file
                      </Button>
                    )}
                    <div className="page-container__main-menu-content-header--right">
                      <NotificationButton
                        newNotifications={mewNotificationsCount}
                        workflowId={workflowId}
                        workflowVersion={workflowVersion}
                        caseId={caseId}
                      />
                      <div className="page-container__main-menu-content-header--divider" />
                      <LogoutLink textContent="Sign out" />
                      <ProfileButton />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {children}
            <div className="page-container__bottom-logo">
              <CoopLogo isPrimaryColor={false} />
            </div>
          </div>
        </div>
      )

    case PageContentContainerVariant.CaseHandlerPage:
      return (
        <div className={PageContentContainerClass}>
          {!isDesktop && (
            <TopBar>
              <CoopLogo />
            </TopBar>
          )}
          {(isDesktop || isLeftNavShowing) && (
            <LeftNavigationBar
              isDesktop={isDesktop}
              isMenuBarExpanded={isLeftNavShowing}
              setIsMenuBarExpanded={setIsLeftNavShowing}
            >
              <div className="logo-with-title-container">
                <CoopLogo />
                <div className="title">Document Exchange</div>
              </div>
              <LinksCollection
                links={coopLinksCaseHandler({
                  externalId:
                    externalId ??
                    localStorage.getItem(LocalStorageKeys.ExternalId) ??
                    "",
                  user: user ?? "",
                  workFlowVersion:
                    workflowVersion ??
                    localStorage.getItem(LocalStorageKeys.WorkflowVersion) ??
                    "",
                  workflowId:
                    workflowId ??
                    localStorage.getItem(LocalStorageKeys.WorkflowId) ??
                    "",
                  navigate,
                })}
              />
            </LeftNavigationBar>
          )}
          <div className="page-container__right-content">
            {children}
            <div className="page-container__bottom-logo">
              <CoopLogo isPrimaryColor={false} />
            </div>
          </div>
        </div>
      )

    case PageContentContainerVariant.ContactUser:
      return (
        <div className={PageContentContainerClass}>
          {(isDesktop || isLeftNavShowing) && (
            <div className={PageContentContainerClass}>
              <LeftNavigationBar
                isMenuBarExpanded={isLeftNavShowing}
                setIsMenuBarExpanded={setIsLeftNavShowing}
                isDesktop={isDesktop}
                leftNavRole="navigation"
              >
                <LinksCollection
                  links={coopLinksContactUser({ navigate, isDesktop })}
                >
                  <CoopLogo />
                </LinksCollection>
              </LeftNavigationBar>
            </div>
          )}
          {!isDesktop && (
            <TopBar>
              <TopBarButtonsCollection
                Logo={<CoopLogo />}
                onSearchClick={() => console.log("Search clicked")}
                onHamburgerClick={() => setIsLeftNavShowing(true)}
                //TODO: Remove this once we have a working search bar
                shouldShowSearch={false}
              />
            </TopBar>
          )}
          <div className="page-container__right-content">
            <div className="page-container__main-menu">
              {isDesktop && (
                <div>
                  <div className="page-container__main-menu-content-header hide-on-tablet-and-down">
                    {/* //TODO: Remove this once we have a working search bar */}
                    {/* <PageContentSearchBar
                      label="Search document"
                      className="page-container__main-menu--search-bar"
                      onSubmit={handleOnSearchBarClick}
                      onClearSearch={navigateToCaseDetailsPage}
                      enableSearchContext
                    /> */}
                    <div className="page-container__main-menu--search-bar" />
                    <div className="page-container__main-menu-content-header--no-border">
                      <LogoutLink textContent="Sign out" />
                      <ProfileButton />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {children}
            <div className="page-container__bottom-logo">
              <CoopLogo isPrimaryColor={false} />
            </div>
          </div>
        </div>
      )
  }
}
