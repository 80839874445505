import { useEffect } from "react"
import { features, getUser } from "react-app-config"
import { convertApiToAppUser } from "../api/api-client/convert"
import { ApiController } from "../api/apiController"
import { UserModuleType } from "../contexts/application/constants"
import { useApplicationContext } from "../contexts/application/context"
import { useToastContext } from "../contexts/toasts"
import { setCurrentUser, useUserContext } from "../contexts/users"
import { loadUserData } from "../contexts/users/loaduserData"

//helper hook for storybook and tests to set initial app state for standard user (What is a standard user?)
// TODO: This list is not complete, we need to add all the modules that are available to advisers, needs investigation and knowledge about features
const adviserModules = [
  UserModuleType.AUDIT_LOG,
  UserModuleType.UPLOAD,
  UserModuleType.SHARED_WITH_ME_EDIT,
  UserModuleType.SHARED_WITH_ME_LIST,
  UserModuleType.SHARED_WITH_ME_VIEW,
  UserModuleType.DATA_EXTRACTION_UPLOAD,
  UserModuleType.SEARCH,
  UserModuleType.DATES,
  UserModuleType.TAGGING,
  UserModuleType.REMINDERS,
  UserModuleType.THUMBNAILS,
  UserModuleType.PREVIEW,
  UserModuleType.CONNECTIVITY,
  UserModuleType.SCRAPING,
  UserModuleType.CLIENT_LIST,
  UserModuleType.CLIENT_INVITE,
  UserModuleType.QUICK_ACCESS,
  UserModuleType.DOCUMENT_LIBRARY_VISIBILITY,
  UserModuleType.DOCUMENT_LIBRARY_NOTIFICATION_BUTTON,
  UserModuleType.CASE_TRACKER,
  UserModuleType.FLAT_FILE_LIST,
]

export const useStandardUser = ({
  email,
  excludedModules = [],
}: {
  email?: string
  excludedModules?: UserModuleType[]
}) => {
  const { dispatch: dispatchUser } = useUserContext()
  const { dispatch } = useApplicationContext()
  const { dispatch: toastDispatch } = useToastContext()

  useEffect(() => {
    const user = getUser({ email })
    const currentUser = convertApiToAppUser(user)

    if (currentUser) {
      if (
        excludedModules &&
        excludedModules.length !== 0 &&
        !currentUser.roles?.includes("Adviser")
      ) {
        currentUser.modules = features
          .map((feature) => feature.feature)
          .filter(
            (features) => !excludedModules.includes(features as UserModuleType)
          )
      }

      if (currentUser.roles?.includes("Adviser"))
        currentUser.modules = adviserModules.filter(
          (module) => !excludedModules.includes(module)
        )
      const api = ApiController.getInstance()
      api.setLocalUserEmail(currentUser.email)
      dispatchUser(setCurrentUser(currentUser))
    }
    loadUserData({ currentUser, dispatch, toastDispatch })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
