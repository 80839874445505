import { createContext, Dispatch, FC, useContext, useReducer } from "react"
import {
  initialState,
  IUploadFileWizardAction,
  IUploadFileWizardState,
  uploadFileWizardReducer,
} from "./reducer"

export const UploadFileWizardContext = createContext(
  {} as {
    uploadFileWizardState: IUploadFileWizardState
    dispatch: Dispatch<IUploadFileWizardAction>
  }
)

export const UploadFileWizardProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [uploadFileWizardState, dispatch] = useReducer(
    uploadFileWizardReducer,
    initialState
  )

  const value = { uploadFileWizardState, dispatch }
  return (
    <UploadFileWizardContext.Provider value={value}>
      {children}
    </UploadFileWizardContext.Provider>
  )
}

export const useUploadFileWizardContext = () =>
  useContext(UploadFileWizardContext)

export * from "./actions"
