import { IFolder } from "../../api/api-client/api-types"
import { TRadioOptions } from "./helpers"

export const getDefaultFolders = (folders: IFolder[]) => {
  const defaultRadioOptions: TRadioOptions[] = []
  folders.forEach((folder) => {
    folder.childFolders?.forEach((child) => {
      const editDisabled =
        child.folderType === "ConnectedFolder" ||
        child.folderType === "PlatformFolder"
      defaultRadioOptions.push({
        group: folder.name,
        label: child.name,
        option: "Never",
        nodeId: child.id,
        contactId: "",
        ...(editDisabled && { disabledOptions: ["Edit"] }),
      })
    })
  })
  return defaultRadioOptions
}
