import { Dispatch, SetStateAction, useEffect } from "react"
import { getFolder } from "../api/api-client/api-handler"
import { IFile } from "../api/api-client/api-types"
import { ApiController } from "../api/apiController"
import { useApplicationContext } from "../contexts/application/context"
import { LocalStorageKeys } from "../contexts/auth/localStorageKeys"
import {
  setDisplaySuccessOrErrorMessage,
  useToastContext,
} from "../contexts/toasts"
import { useUserContext } from "../contexts/users"
import { notificationMessages } from "../data/notificationMessages"
import { AppType } from "../utils/enums/AppType"
import { GetGAFolderType, sendGaData } from "../utils/gaEvents"

export const useDownloadFile = ({
  file,
  isDownloadingFile,
  setIsDownloading,
}: {
  file?: IFile
  isDownloadingFile: boolean
  setIsDownloading: Dispatch<SetStateAction<boolean>>
}) => {
  const {
    applicationState: { folders, sharedWithMeFolders },
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()
  const { dispatch: toastDispatch } = useToastContext()

  useEffect(() => {
    const downloadFile = async () => {
      const parentFolder = getFolder({
        folders: file?.isOwner ? folders : sharedWithMeFolders ?? [],
        folderId: file?.parentId ?? "",
      })

      try {
        var extension = file?.extension?.toLowerCase()
        var fileName = file?.name + "." + extension

        sendGaData({
          name: "file_download",
          file_extension: file?.extension?.toLowerCase(),
          is_node_owner: file?.isOwner ? "Y" : "N",
          ...(parentFolder && {
            folder_name: parentFolder.name,
            folder_depth: parentFolder.level,
            folder_type: GetGAFolderType(parentFolder),
            currentUser,
          }),
        })

        const api = ApiController.getInstance()
        const response = await api.download(
          file?.id ?? "",
          fileName,
          file?.mimeType ?? "text/plain"
        )

        if (window.parent) {
          window.parent.postMessage(
            {
              fileName,
              fileId: file?.id,
              fileType: file?.mimeType ?? "text/plain",
              eventType: "fileDownload",
            },
            "*"
          )
        }

        // no success toast required as the file download confirmation is handled by the browser
        if (response) {
          sendGaData({
            name: "file_download_response",
            file_extension: file?.extension?.toLowerCase(),
            is_node_owner: file?.isOwner ? "Y" : "N",
            request_status: "success",
            ...(parentFolder && {
              folder_name: parentFolder.name,
              folder_depth: parentFolder.level,
              folder_type: GetGAFolderType(parentFolder),
              currentUser,
            }),
          })

          const appTypeFromLocalStorage = localStorage.getItem(
            LocalStorageKeys.AppType
          )
          if (
            appTypeFromLocalStorage === AppType.Ios ||
            appTypeFromLocalStorage === AppType.Android
          ) {
            return
          }
        }
      } catch (e) {
        sendGaData({
          name: "file_download_response",
          file_extension: file?.extension?.toLowerCase(),
          is_node_owner: file?.isOwner ? "Y" : "N",
          request_status: "error",
          ...(parentFolder && {
            folder_name: parentFolder.name,
            folder_depth: parentFolder.level,
            folder_type: GetGAFolderType(parentFolder),
            currentUser,
          }),
        })

        toastDispatch(
          setDisplaySuccessOrErrorMessage({
            message: notificationMessages.downloadFile.ERROR,
            messageType: "ERROR",
          })
        )
      } finally {
        setIsDownloading(false)
      }
    }

    if (isDownloadingFile && file) {
      downloadFile()
    }
  }, [
    isDownloadingFile,
    file,
    folders,
    sharedWithMeFolders,
    currentUser,
    toastDispatch,
    setIsDownloading,
  ])
}
