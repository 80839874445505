import {
  ActivityLogEntryDto,
  DistributionProjectInfoDto,
  FlatFileNodeDto,
  Industry,
  NotificationDto,
  Provider,
  ProviderAccountDto,
  PubSubNotificationDto,
  SharedVaultDto,
  UpdateDto,
} from "legado-generated-api-client"
import { Dispatch } from "react"
import { getDocumentLibraryController } from "../../api/ApiControllers/helpers"
import {
  avivaExampleData,
  britishGasExampleData,
  dvlaExampleData,
  netflixExampleData,
} from "../../component/Connectivity/ConnectedAccountDetails/data"
import { deleteFile } from "../../component/DeleteNode/helpers"
import { TTag } from "../../component/ReviewSection/TagReview/TagReview"
import {
  setLoadingState,
  setSelectedProject,
} from "../../contexts/application/action"
import { IThumbnailAction } from "../../contexts/thumbnails/reducer"
import { setDisplaySuccessOrErrorMessage } from "../../contexts/toasts"
import { IToastAction } from "../../contexts/toasts/reducer"
import { sharedFoldersToIFolders } from "../../utils/folder/sharedFoldersToIFolder"
import {
  getItemLocalStorage,
  setItemLocalStorage,
} from "../../utils/localStorage"
import { ApiController } from "../apiController"
import { getBulkDistributionController } from "../ApiControllers/helpers"
import {
  IFeatureSetResponse,
  IFile,
  IFolder,
  INodeShare,
  INotification,
  IUser,
} from "./api-types"
import {
  convertApiToAppNodeShares,
  convertApiToAppUser,
  convertApiToAppUsers,
  convertAppToApiNodeShares,
  convertDtoToIFolders,
  fromContactResponseToContactList,
} from "./convert"

export const getSharesForNode = async (nodeId: string) => {
  const api = ApiController.getInstance()
  const nodeShares = await api.getSharesForNode(nodeId)
  if (nodeShares) {
    return convertApiToAppNodeShares(nodeShares)
  }
}

export const getFoldersSharedByUser = async (userId: string) => {
  const api = ApiController.getInstance()
  const nodes = (await api.getFoldersSharedByUser(userId)) as SharedVaultDto
  const tree = sharedFoldersToIFolders(nodes)
  return tree
}

export const getFoldersSharedByClient = async (
  userId: string,
  includeDocumentRequestFiles?: boolean
) => {
  const api = ApiController.getInstance()
  const nodes = (await api.getFoldersSharedByClient(
    userId,
    includeDocumentRequestFiles
  )) as SharedVaultDto
  const tree = sharedFoldersToIFolders(nodes)
  return tree
}

export const getFlatFileListSharedByUser = async (
  userId: string,
  includeDocumentRequestFiles: boolean = false
) => {
  const api = ApiController.getInstance()
  const nodes = await api.getFoldersSharedByClient(
    userId,
    includeDocumentRequestFiles
  )
  return nodes?.files
}
export const getFlatFileListForCustomerAccount = async ({
  secondAttempt = false,
  shouldRefreshCache = false,
  includeRequestFiles = false,
}: {
  secondAttempt?: boolean
  shouldRefreshCache?: boolean
  includeRequestFiles?: boolean
}) => {
  const api = ApiController.getInstance()
  const nodes = await api.getFlatFileList({
    secondAttempt,
    shouldRefreshCache,
    includeRequestFiles,
  })
  return nodes
}

export const getVaultSharedByExternalUser = async (
  accountId: string | undefined,
  personId: string | undefined
) => {
  if (accountId) {
    const api = ApiController.getInstance()
    return await api.getAdviserFilesForClientAccount(accountId)
  } else if (personId) {
    const api = getDocumentLibraryController()
    return await api.getFilesForClientAccount(personId)
  }
  throw new Error("One of Person ID or Account ID must be specified")
}

export const getUsersSharingWithCurrentUser = async () => {
  const api = ApiController.getInstance()
  const all = await api.getUsersSharingWithCurrentUser()
  return convertApiToAppUsers(all)
}

export const getClientList = async () => {
  const api = ApiController.getInstance()
  const clientList = await api.getSharingClientsList()
  const clientsAsIUsers = convertApiToAppUsers(clientList)
  return clientsAsIUsers
}

export const getAllFolders = async () => {
  const api = ApiController.getInstance()
  const all = await api.getFolders()

  return convertDtoToIFolders(all)
}

export const getUserContacts = async () => {
  const apiController = ApiController.getInstance()
  const contactResponse = await apiController.getContacts()
  return await fromContactResponseToContactList(contactResponse)
}

export const getOrgContacts = async () => {
  const apiController = ApiController.getInstance()
  const contactResponse = await apiController.getOrgContacts()
  return contactResponse
}

export const getNodesSharedWithContact = async ({
  contactId,
}: {
  contactId: string
}) => {
  const api = ApiController.getInstance()
  const nodes = await api.getNodesSharedWithContact(contactId)
  return convertApiToAppNodeShares(nodes)
}

export const postNodeSharesForContact = async (
  contactId: string,
  contactNodeShares: INodeShare[]
) => {
  const api = ApiController.getInstance()
  const apiNodeShares = convertAppToApiNodeShares(contactNodeShares)
  return await api.postNodeSharesForContact(contactId, apiNodeShares)
}

export const getContactsForNode = async (contactId: string) => {
  const api = ApiController.getInstance()
  const nodes = await api.getContactsForNode(contactId)
  return convertApiToAppNodeShares(nodes)
}

export const getActivitiesLog = async (getRecent?: boolean) => {
  const api = ApiController.getInstance()
  const activities = getRecent
    ? await api.getRecentActivities()
    : await api.getAllActivities()
  const appActivities = activities && activities.length > 0 ? activities : []
  return removeDuplicates(appActivities)
}

export const getActivityLog = async (nodeId: string) => {
  const api = ApiController.getInstance()
  const recentActions = await api.getActivityLog(nodeId)
  return recentActions
}

export const getIUser = async ({
  userId,
  email,
}: {
  userId?: string
  email?: string
}) => {
  const api = ApiController.getInstance()
  const user = await api.getUser(userId, email)
  return convertApiToAppUser(user)
}

export const putAcceptTerms = async () => {
  const api = ApiController.getInstance()
  return api.putAcceptTerms()
}

const findFolderNotClone = ({
  folders,
  find,
  id,
}: {
  folders: IFolder[]
  find: IFolder[]
  id: string
}) => {
  let foundIt = false
  folders.forEach((folder) => {
    if (folder.id === id) {
      find.push(folder)
      foundIt = true
      return
    }
  })
  if (foundIt) {
    return
  }
  folders.forEach((f) => {
    if (f.childFolders) {
      findFolderNotClone({ folders: f.childFolders, find, id })
    }
  })
}

export const getFolderNotClone = ({
  folders,
  folderId,
}: {
  folders: IFolder[]
  folderId: string
}) => {
  const find = [] as IFolder[]
  findFolderNotClone({ folders, find, id: folderId })
  return find[0]
}

const findFolder = ({
  folders,
  find,
  id,
}: {
  folders: IFolder[]
  find: IFolder[]
  id: string
}) => {
  const found = folders.find((f) => f.id === id)
  if (found) {
    find.push(found)
    return
  }
  folders.forEach((f) => {
    if (f.childFolders) {
      findFolder({ folders: f.childFolders, find, id })
    }
  })
}

export const getApiFolder = (
  { folderId }: { folderId: string },
  allFolders: IFolder[]
) => {
  const find = [] as IFolder[]
  findFolder({ folders: allFolders, find, id: folderId })
  return find
}

export const getFolder = ({
  folders,
  folderId,
}: {
  folders: IFolder[]
  folderId: string
}) => {
  const find = [] as IFolder[]
  findFolder({ folders, find, id: folderId })
  return find[0]
}

export type TIdName = {
  id: string
  name: string
  onClick?: () => void
}

const addParentIdAndName = async ({
  folders,
  folder,
  path,
}: {
  folders: IFolder[]
  folder: IFolder
  path: TIdName[]
}) => {
  path.unshift({ id: folder.id, name: folder.name })
  if (folder.level > 0) {
    const parent = getApiFolder({ folderId: folder.parentId || "" }, folders)
    if (parent && parent[0]) {
      addParentIdAndName({
        folders,
        folder: parent[0],
        path,
      })
    }
  }
}

export const getFolderPath = (folderId: string, allFolders: IFolder[]) => {
  const folders = getApiFolder({ folderId }, allFolders)
  if (folders && folders[0]) {
    const path = [] as TIdName[]
    addParentIdAndName({
      folders: allFolders,
      folder: folders[0],
      path,
    })
    return path
  }
}

export const getThumbnailForFile = async ({
  fileId,
  isFullSizeThumbnail = false,
}: {
  fileId: string
  isFullSizeThumbnail?: boolean
}) => {
  const api = ApiController.getInstance()
  const base64StringThumbnail = await api.getThumbnail(
    fileId,
    false,
    isFullSizeThumbnail ? isFullSizeThumbnail : false
  )
  return base64StringThumbnail
}

export const getFilesAndFoldersPinned = async () => {
  const api = ApiController.getInstance()
  const pinnedData = await api.getPinnedFoldersAndFiles()
  return pinnedData
}

//Gets img data for img types, pdf from Syncfusion for rest
//Saves to browser storage
export const getPreviewDocumentPath = async (
  file: IFile,
  logPreview: boolean
) => {
  if (file.preview && file.preview.length > 0) {
    return file.preview
  }
  const api = ApiController.getInstance()
  const path = await api.getPreviewForFile(file.id, logPreview)
  if (!path || path === "") return ""
  var preview = "data:application/pdf;base64,".concat(path)
  return preview
}

export const getThumbnailAndStore = async (file: IFile) => {
  const thumbnailLocalStorage = getItemLocalStorage("thumbnail" + file.id)
  if (thumbnailLocalStorage) {
    return thumbnailLocalStorage
  }
  const api = ApiController.getInstance()
  const res = api.getThumbnail(file.id)
  setItemLocalStorage("thumbnail" + file.id, res)
  return res
}

const removeDuplicates = async (activities: ActivityLogEntryDto[]) => {
  const uniqueActivityLogs: ActivityLogEntryDto[] = []
  activities.forEach((a) => {
    if (
      uniqueActivityLogs.find(
        (f) =>
          f.nodeId === a.nodeId &&
          f.action === a.action &&
          f.created === a.created
      ) === undefined
    ) {
      uniqueActivityLogs.push(a)
    }
  })
  return uniqueActivityLogs
}

export const getFeatureSetAsModules = async () => {
  const apiController = ApiController.getInstance()
  const res = await apiController.getFeatureSet()
  if (res) {
    const backendModules = res as unknown as IFeatureSetResponse
    const theme = backendModules.theme

    const features = backendModules.featureDtos

    if (features && features.length > 0) {
      const enabled = features.filter((f) => f.enabled)
      const names = enabled.map((e) => {
        return e.name
      })
      names.unshift(theme)
      return names
    }
  }
  return null
}

export const getNotifications = async () => {
  const apiController = ApiController.getInstance()
  const res = await apiController.getNotifications()
  if (res) {
    const notifications = res as unknown as INotification[]
    return notifications
  }
  return []
}

export const postNotificationsToReadApi = async (ids: string[]) => {
  const apiController = ApiController.getInstance()
  const res = await apiController.setNotificationsToRead(ids)
  return res as unknown as UpdateDto
}

export const getProviders = async () => {
  const apiController = ApiController.getInstance()
  const res = await apiController.getProviderList()
  return res as unknown as Provider[]
}

export const getIndustries = async () => {
  const apiController = ApiController.getInstance()
  const res = await apiController.getIndustryList()
  return res as unknown as Industry[]
}

export const getNotificationContent = async (
  notification: PubSubNotificationDto
) => {
  const apiController = ApiController.getInstance()
  const res = await apiController.getNotificationContent(notification)
  return res as unknown as NotificationDto
}

export const getFileById = async (fileId: string) => {
  const apiController = ApiController.getInstance()
  const res = await apiController.getFileById(fileId)
  return res as unknown as IFile
}
export const getFlatFileById = async (flatFileId: string) => {
  const apiController = ApiController.getInstance()
  const res = await apiController.getFlatFileById(flatFileId)
  return res as unknown as FlatFileNodeDto
}

export const getFolderById = async (folderId: string) => {
  const apiController = ApiController.getInstance()
  const res = await apiController.getFolderById(folderId)
  return res as unknown as IFolder
}

export const getFolderContents = async (folderId: string) => {
  const apiController = ApiController.getInstance()
  const res = await apiController.getFolderContents(folderId)
  return res as IFile[]
}

export const deleteFolderConnection = async (
  folder: IFolder,
  deleteImportedFiles: boolean,
  dispatch: Dispatch<any>,
  toastDispatch: Dispatch<IToastAction>,
  folders: IFolder[],
  sharedWithMeFolders: IFolder[] | undefined,
  pinnedFoldersAndFiles: IFolder | undefined,
  currentUser: IUser | undefined
) => {
  const apiController = ApiController.getInstance()
  if (deleteImportedFiles) {
    folder.contents?.forEach(async (file) => {
      if (file.isExternalDocument) {
        await deleteFile(
          file,
          false,
          () => {},
          dispatch,
          toastDispatch,
          folders,
          sharedWithMeFolders,
          pinnedFoldersAndFiles,
          currentUser
        )
      }
    })
  }
  const res = await apiController.disconnectConnectedFolder(folder.id)
  toastDispatch(
    setDisplaySuccessOrErrorMessage({
      message: "Files have been removed",
      messageType: "SUCCESS",
    })
  )

  return res
}

export const reconnectFolderConnection = async (folderId: string) => {
  const apiController = ApiController.getInstance()
  const res = await apiController.reconnectConnectedFolder(folderId)
  return res
}

export const getRemindersForFile = async (fileId?: string) => {
  if (fileId) {
    const apiController = ApiController.getInstance()
    const reminders = await apiController.getReminders()
    if (reminders) {
      return reminders.filter((r) => r.fileId === fileId)
    }
  }
  return []
}

export const getRemindersForFolder = async (folderId?: string) => {
  if (folderId) {
    const apiController = ApiController.getInstance()
    const reminders = await apiController.getReminders()
    if (reminders) {
      return reminders.filter((r) => r.folderId === folderId)
    }
  }
  return []
}

export const getLocalProviderAccountInfo = (folder: IFolder) => {
  switch (folder.externalCode?.toLocaleLowerCase()) {
    case "dvla":
      return dvlaExampleData
    case "netflix":
      return netflixExampleData
    case "aviva":
      return avivaExampleData
    default:
      return britishGasExampleData
  }
}

export const getProviderFolderName = (providerAccount: ProviderAccountDto) => {
  if (providerAccount.providerCode === "dvla") {
    return "Vehicle: " + providerAccount.username
  }
  return providerAccount.providerCode
}

export const setFolderStatusesAsRead = async (folderIds: string[]) => {
  const api = ApiController.getInstance()
  return api.setFolderStatusesAsRead(folderIds)
}

export const deleteClient = async (clientId: string) => {
  const api = ApiController.getInstance()
  return api.removeClient(clientId)
}

export const stringToTags = (tagArray: string[] | null | undefined) => {
  if (!tagArray || tagArray[0] === "[]") {
    return []
  }
  const emptyFiltered = tagArray.filter((t) => t !== "")
  const tTags = emptyFiltered.map((tag: string) => {
    return {
      confidence: 0,
      type: "unreadInfo",
      name: tag,
    } as TTag
  })
  return tTags
}

export const getProjectList = async () => {
  const api = getBulkDistributionController()
  return await api.getProjectList()
}

export const restoreDocument = async (
  file: IFile,
  project: DistributionProjectInfoDto,
  dispatch: Dispatch<any>,
  toastDispatch: Dispatch<IToastAction>
) => {
  if (file?.id) {
    const api = ApiController.getInstance()
    const res = await api.restoreDocument(file.id)
    if (res) {
      file.deleted = false
      project.versionedFiles?.map((versionedFiles) => {
        versionedFiles.files?.map((vfile) => {
          if (vfile.id === file.id) {
            vfile.deleted = false
          }
          return vfile
        })
        return versionedFiles
      })
      dispatch(setSelectedProject(project))
      toastDispatch(
        setDisplaySuccessOrErrorMessage({
          message: "Document restored successfully",
          messageType: "SUCCESS",
        })
      )
    } else {
      toastDispatch(
        setDisplaySuccessOrErrorMessage({
          message: "Could not restore document",
          messageType: "ERROR",
        })
      )
    }
  }
}

// @ts-ignore-next-line
const isCypress = typeof Cypress !== "undefined"
const isJest = typeof jest !== "undefined"

export const connectToUsersWebSocket = async (
  selectedSharingWithMeUserId: string,
  dispatch: Dispatch<any>,
  thumbnailDispatch: (value: IThumbnailAction) => void,
  toastDispatch: Dispatch<IToastAction>,
  theme: string,
  relationship: "client" | "contact",
  currentUser?: IUser
) => {
  const api = ApiController.getInstance()
  if (selectedSharingWithMeUserId) {
    // We don't want to set up webhooks when running Cypress or Jest tests, as it will cause the tests to fail and show errors.
    if (!isCypress && !isJest) {
      await api.joinAnotherUserWebHook(
        selectedSharingWithMeUserId,
        dispatch,
        thumbnailDispatch,
        toastDispatch,
        theme,
        relationship,
        currentUser
      )
    }
  }
  dispatch(setLoadingState(false))
}
