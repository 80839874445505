import classNames from "classnames"
import { PAGE_PATH, TLink } from "../../contexts/application/constants"
import { useApplicationContext } from "../../contexts/application/context"
import "./LeftNavigationBar.css"

export interface INavLink {
  name: string
  href?: string
  iconName?: string
  subLinks?: INavLink[]
  isActiveLink?: boolean
  onClick?: () => void
  disabled?: boolean
}

const pagesWithoutMenuItems = [PAGE_PATH.AccountDocumentLibraryPage]

export const filterMenuItemsByCurrentPage = (allMenuItems: TLink[]) => {
  const pagePath = new URL(window.location.href).pathname
  return pagesWithoutMenuItems.includes(pagePath) ? [] : allMenuItems
}

export const LeftNavigationBar = ({
  className,
  isDesktop,
  setIsMenuBarExpanded,
  isMenuBarExpanded,
  children,
  leftNavRole,
}: {
  className?: string
  setIsMenuBarExpanded: (isExpanded: boolean) => void
  isMenuBarExpanded: boolean
  isDesktop: boolean
  children?: React.ReactNode
  leftNavRole?: string
}) => {
  const {
    applicationState: { selectedTheme },
  } = useApplicationContext()

  const leftNavContentClass = classNames(className, {
    "left-nav-content": selectedTheme !== "CO_OP",
    "left-nav-content--sticky": selectedTheme === "CO_OP",
  })

  return (
    <div className="cover-and-left-nav">
      {isMenuBarExpanded && !isDesktop && (
        <div
          onClick={() => setIsMenuBarExpanded(false)}
          className="cover-screen"
        />
      )}
      <div
        className={classNames("left-navigation", className)}
        data-testid="leftNavigationBar-mainDiv"
        role={leftNavRole ?? "contentinfo"}
      >
        <div className={leftNavContentClass}>{children}</div>
      </div>
    </div>
  )
}
