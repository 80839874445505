import { baseUrl, httpClient } from "../httpClient"
import { GetCaseActionsResponse } from "./models/GetCaseActionsResponse"
import { CaseDocument } from "./models/GetCaseDocumentResponse"
import { GetWorkflowAnalyticsCasesResponse } from "./models/GetWorkflowAnalyticsCasesResponse"
import { GetWorkflowAnalyticsCaseStageStatusInfoResponse } from "./models/GetWorkflowAnalyticsCaseStageStatusInfoResponse"
import { GetWorkflowAnalyticsCaseSummaryResponse } from "./models/GetWorkflowAnalyticsCaseSummaryResponse"
import { GetWorkflowAnalyticsDocumentsResponse } from "./models/GetWorkflowAnalyticsDocumentsResponse"
import { GetWorkflowAnalyticsDocumentStatusInfoResponse } from "./models/GetWorkflowAnalyticsDocumentStatusInfoResponse"
import { GetWorkflowAnalyticsDocumentSummaryResponse } from "./models/GetWorkflowAnalyticsDocumentSummaryResponse"

import {
  CaseListInfoResponse,
  GetWorkFlowCaseByExternalIdResponse,
  GetWorkFlowCaseResponse,
} from "./models/GetWorkflowCaseResponse"
import { GetWorkflowResponse } from "./models/GetWorkflowResponse"

export const getWorkflowInfoForUser =
  async (): Promise<GetWorkflowResponse> => {
    const url = new URL(`${baseUrl}/api/Workflow/info`)

    const response = await httpClient(url.toString(), {
      method: "GET",
    })

    return response.data
  }

export const getWorkFlowCase = async ({
  workflowId,
  workflowVersion,
  caseId,
}: {
  workflowId: string
  workflowVersion: string
  caseId: string
}): Promise<GetWorkFlowCaseResponse> => {
  const url = new URL(
    `${baseUrl}/api/Workflow/${workflowId}/${workflowVersion}/case/${caseId}`
  )

  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const getWorkFlowCaseList = async (): Promise<
  CaseListInfoResponse[]
> => {
  const url = new URL(`${baseUrl}/api/Workflow/case/list`)

  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const postUploadCaseDocument = async (
  workflowId: string,
  workflowVersion: string,
  caseId: string,
  formData: FormData,
  stageId?: string,
  requestId?: string
): Promise<CaseDocument> => {
  const url = new URL(
    `${baseUrl}/api/Workflow/${workflowId}/${workflowVersion}/case/${caseId}/documents/upload?stageId=${
      stageId ?? ""
    }&requestId=${requestId ?? ""}`
  )

  const response = await httpClient(url.toString(), {
    method: "POST",
    data: formData,
  })

  return response.data
}

export const getWorkFlowCaseDocuments = async ({
  workflowId,
  workflowVersion,
  caseId,
}: {
  workflowId: string
  workflowVersion: string
  caseId: string
}): Promise<CaseDocument[]> => {
  const url = new URL(
    `${baseUrl}/api/Workflow/${workflowId}/${workflowVersion}/case/${caseId}/documents`
  )

  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const getWorkflowCaseDocumentByExternalId = async ({
  externalId,
}: {
  externalId: string
}): Promise<CaseDocument[]> => {
  const url = new URL(`${baseUrl}/api/Workflow/case/${externalId}/documents`)

  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const getSingleCaseDocument = async ({
  workflowId,
  workflowVersion,
  caseId,
  stageId,
  requestId,
  documentId,
}: {
  workflowId: string
  workflowVersion: string
  caseId: string
  stageId: string
  requestId: string
  documentId: string
}): Promise<CaseDocument> => {
  const url = new URL(
    `${baseUrl}/api/Workflow/${workflowId}/${workflowVersion}/case/${caseId}/stage/${stageId}/request/${requestId}/document/${documentId}`
  )

  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const deleteWorkflowCaseDocument = async (
  workflowId: string,
  workflowVersion: string,
  caseId: string,
  stageId: string,
  requestId: string,
  documentId: string
): Promise<boolean> => {
  const url = new URL(
    `${baseUrl}/api/Workflow/${workflowId}/${workflowVersion}/case/${caseId}/stage/${stageId}/request/${requestId}/document/${documentId}`
  )

  const response = await httpClient(url.toString(), {
    method: "DELETE",
  })

  return response.data
}

export const getWorkFlowCaseActions = async ({
  workflowId,
  workflowVersion,
  caseId,
}: {
  workflowId: string
  workflowVersion: string
  caseId: string
}): Promise<GetCaseActionsResponse[]> => {
  const url = new URL(
    `${baseUrl}/api/Workflow/${workflowId}/${workflowVersion}/case/${caseId}/actions`
  )

  const response = await httpClient(url.toString(), {
    method: "GET",
  })
  return response.data
}

export const deleteCaseFile = async (
  workflowId: string,
  workflowVersion: string,
  caseId: string,
  stageId: string,
  requestId: string,
  documentId: string
): Promise<boolean> => {
  const url = new URL(
    `${baseUrl}/api/workflow/${workflowId}/${workflowVersion}/case/${caseId}/stage/${stageId}/request/${requestId}/document/${documentId}`
  )

  const response = await httpClient(url.toString(), {
    method: "DELETE",
  })

  return response.data
}

export enum CaseDocumentActionId {
  Upload = 5,
  Acknowledge = 6,
  Reject = 7,
  Comment = 8,
}

export const putWorkflowCaseDocumentAction = async ({
  workflowId,
  workflowVersion,
  caseId,
  stageId,
  requestId,
  documentId,
  actionId,
  userId,
  comment = "",
}: {
  workflowId: string
  workflowVersion: string
  caseId: string
  stageId: string
  requestId: string
  documentId: string
  actionId: number
  userId?: string
  comment?: string
}): Promise<CaseDocument> => {
  const url = new URL(
    `${baseUrl}/api/Workflow/${workflowId}/${workflowVersion}/case/${caseId}/stage/${stageId}/request/${requestId}/document/${documentId}/actions`
  )

  const response = await httpClient(url.toString(), {
    method: "PUT",
    data: {
      actionId: actionId,
      userId: userId,
      comment: comment,
    },
  })

  return response.data
}

export const putDocumentToWorkflowCase = async (
  workflowId: string,
  workflowVersion: string,
  caseId: string,
  stageId: string,
  requestId: string,
  fileId: string
): Promise<CaseDocument> => {
  const url = new URL(
    `${baseUrl}/api/Workflow/${workflowId}/${workflowVersion}/case/${caseId}/file/${fileId}/attach`
  )

  const response = await httpClient(url.toString(), {
    method: "PUT",
    data: {
      targetStageId: stageId,
      targetRequestId: requestId,
    },
  })

  return response.data
}

export const patchUpdateCaseDocument = async ({
  workflowId,
  workflowVersion,
  caseId,
  stageId,
  requestId,
  documentId,
  uploadedViaCategory,
  documentName,
  fileName,
  targetRequestId,
}: {
  workflowId: string
  workflowVersion: string
  caseId: string
  stageId: string
  requestId: string
  documentId: string
  uploadedViaCategory: boolean
  documentName?: string
  fileName?: string
  targetRequestId: string
}): Promise<CaseDocument> => {
  const url = new URL(
    `${baseUrl}/api/Workflow/${workflowId}/${workflowVersion}/case/${caseId}/stage/${stageId}/request/${requestId}/document/${documentId}`
  )

  const response = await httpClient(url.toString(), {
    method: "PATCH",
    data: {
      name: documentName,
      // description: "", //TODO: What's a document description? https://dev.azure.com/secure-the-file/Application/_workitems/edit/15319
      updated: new Date(),
      fileName: fileName,
      targetRequestId: targetRequestId,
      uploadedViaCategory,
    },
  })

  return response.data
}

// TODO: Check why we have another response ? https://dev.azure.com/secure-the-file/Application/_workitems/edit/15320
export const getWorkFlowCaseByExternalId = async ({
  externalId,
}: {
  externalId: string
}): Promise<GetWorkFlowCaseByExternalIdResponse> => {
  const url = new URL(`${baseUrl}/api/Workflow/case/${externalId}`)

  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const patchEnableOrDisableDocumentReminder = async ({
  workflowId,
  workflowVersion,
  caseId,
  stageId,
  requestId,
  remindersEnabled,
}: {
  workflowId: string
  workflowVersion: string
  caseId: string
  stageId: string
  requestId: string
  remindersEnabled: boolean
}): Promise<CaseDocument> => {
  const url = new URL(
    `${baseUrl}/api/Workflow/${workflowId}/${workflowVersion}/case/${caseId}/stage/${stageId}/request/${requestId}?remindersEnabled=${remindersEnabled}`
  )

  const response = await httpClient(url.toString(), {
    method: "PATCH",
  })

  return response.data
}

export const postWorkflowDocuSignSent = async ({
  workflowId,
  workflowVersion,
  caseId,
  fileId,
}: {
  workflowId: string
  workflowVersion: string
  caseId: string
  fileId: string
}): Promise<CaseDocument> => {
  const url = new URL(
    `${baseUrl}/api/Workflow/${workflowId}/${workflowVersion}/case/${caseId}/docusign-sent`
  )

  const response = await httpClient(url.toString(), {
    method: "POST",
    data: {
      fileId,
    },
  })

  return response.data
}

export const postWorkflowDocuSignSigned = async ({
  workflowId,
  workflowVersion,
  caseId,
  fileId,
}: {
  workflowId: string
  workflowVersion: string
  caseId: string
  fileId: string
}): Promise<CaseDocument> => {
  const url = new URL(
    `${baseUrl}/api/Workflow/${workflowId}/${workflowVersion}/case/${caseId}/docusign-signed`
  )

  const response = await httpClient(url.toString(), {
    method: "POST",
    data: {
      fileId,
    },
  })

  return response.data
}

export const getWorkflowFile = async ({
  fileId,
}: {
  fileId: string
}): Promise<CaseDocument> => {
  const url = new URL(`${baseUrl}/api/Workflow/file/${fileId}`)

  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const deleteWorkflowCaseRequest = async ({
  workflowId,
  workflowVersion,
  caseId,
  stageId,
  requestId,
}: {
  workflowId: string
  workflowVersion: string
  caseId: string
  stageId: string
  requestId: string
}): Promise<GetWorkFlowCaseResponse> => {
  const url = new URL(
    `${baseUrl}/api/Workflow/${workflowId}/${workflowVersion}/case/${caseId}/stage/${stageId}/request/${requestId}`
  )

  const response = await httpClient(url.toString(), {
    method: "DELETE",
  })

  return response.data
}

export const getWorkflowAnalyticsDocumentSummary = async ({
  workflowId,
}: {
  workflowId: string
}): Promise<GetWorkflowAnalyticsDocumentSummaryResponse> => {
  const url = new URL(
    `${baseUrl}/api/WorkflowAnalytics/${workflowId}/document/summary`
  )

  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const getWorkflowAnalyticsCaseSummary = async ({
  workflowId,
}: {
  workflowId: string
}): Promise<GetWorkflowAnalyticsCaseSummaryResponse> => {
  const url = new URL(
    `${baseUrl}/api/WorkflowAnalytics/${workflowId}/case/summary`
  )

  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const getWorkflowAnalyticsDocumentStatusInfo = async ({
  workflowId,
  startDate,
  endDate,
}: {
  workflowId: string
  startDate: string
  endDate: string
}): Promise<GetWorkflowAnalyticsDocumentStatusInfoResponse[]> => {
  const url = new URL(
    `${baseUrl}/api/WorkflowAnalytics/${workflowId}/document/statusinfo`
  )
  url.searchParams.append("startdate", startDate)
  url.searchParams.append("enddate", endDate)
  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const getWorkflowAnalyticsCaseStageStatusInfo = async ({
  workflowId,
  startDate,
  endDate,
}: {
  workflowId: string
  startDate: string
  endDate: string
}): Promise<GetWorkflowAnalyticsCaseStageStatusInfoResponse[]> => {
  const url = new URL(
    `${baseUrl}/api/WorkflowAnalytics/${workflowId}/case/stage/statusinfo`
  )
  url.searchParams.append("startdate", startDate)
  url.searchParams.append("enddate", endDate)
  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const getWorkflowAnalyticsDocuments = async ({
  workflowId,
}: {
  workflowId: string
}): Promise<GetWorkflowAnalyticsDocumentsResponse[]> => {
  const url = `${baseUrl}/api/WorkflowAnalytics/${workflowId}/document/list`

  const response = await httpClient(url, {
    method: "GET",
  })

  return response.data
}

export const getWorkflowAnalyticsDocumentCsv = async ({
  workflowId,
}: {
  workflowId: string
}): Promise<string> => {
  const url = `${baseUrl}/api/WorkflowAnalytics/${workflowId}/documents/csv`

  const response = await httpClient(url, {
    method: "GET",
  })

  return response.data
}

export const getWorkflowAnalyticsCases = async ({
  workflowId,
}: {
  workflowId: string
}): Promise<GetWorkflowAnalyticsCasesResponse[]> => {
  const url = `${baseUrl}/api/WorkflowAnalytics/${workflowId}/case/list`

  const response = await httpClient(url, {
    method: "GET",
  })

  return response.data
}

export const getWorkflowAnalyticsCaseCsv = async ({
  workflowId,
}: {
  workflowId: string
}): Promise<string> => {
  const url = `${baseUrl}/api/WorkflowAnalytics/${workflowId}/case/csv`

  const response = await httpClient(url, {
    method: "GET",
  })

  return response.data
}
