import { Dispatch, FC, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { IFile } from "../../../api/api-client/api-types"
import {
  setSelectedFile,
  setSelectedFolder,
} from "../../../contexts/application/action"
import { PAGE_PATH, UserRoles } from "../../../contexts/application/constants"
import { useApplicationContext } from "../../../contexts/application/context"
import {
  setDisplaySuccessOrErrorMessage,
  useToastContext,
} from "../../../contexts/toasts"
import {
  SUCCESS_NOTIICATION_DISAPPEARS_AFTER_MS,
  TDisplaySuccessOrErrorPopup,
  TMessage,
  TMessageTypes,
} from "../../../contexts/toasts/constants"
import { IToastAction } from "../../../contexts/toasts/reducer"
import { useUserContext } from "../../../contexts/users"
import { notificationMessages } from "../../../data/notificationMessages"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import { useOutsideAlerter } from "../../../utils/outsideClickAlerter"
import "./NotificationPopUp.css"

export const showSuccessOrErrorMessage = ({
  hasError,
  methodName,
  toastDispatch,
}: {
  hasError?: boolean
  methodName: string
  toastDispatch: Dispatch<IToastAction>
}) => {
  if (hasError) {
    const errorMsg =
      notificationMessages[methodName]?.ERROR || "There was an error"
    toastDispatch(
      setDisplaySuccessOrErrorMessage({
        message: errorMsg,
        messageType: "ERROR",
      })
    )
    return
  }

  const successMsg = notificationMessages[methodName]?.SUCCESS
  if (successMsg) {
    toastDispatch(
      setDisplaySuccessOrErrorMessage({
        message: successMsg,
        messageType: "SUCCESS",
      })
    )
  }
}

type notificationPopUpMessageProps = {
  message: TMessage
  messageType: TMessageTypes
  closePopup: () => void
  displayCloseButton?: boolean
}
const NotificationPopUpMessage: FC<notificationPopUpMessageProps> = ({
  messageType,
  message,
  closePopup,
  displayCloseButton = true,
}) => {
  const CheckMarkIcon = useGetIcon("NotificationSuccess")
  const errorIcon = useGetIcon("NotificationError")
  const CrossIcon = useGetIcon("Cross")
  const NotificationIcon = useGetIcon("OutlineBell")
  const ProgressIcon = useGetIcon("Progress")
  const InfoIcon = useGetIcon("Info")
  const FilledError = useGetIcon("FilledError")

  return (
    <div
      className="single-notification-message"
      role={messageType === "ERROR" ? "alertdialog" : "status"}
      aria-label={messageType}
      aria-describedby="alert-info"
    >
      {/* TODO: Refactor this code so the terenary operator isn't so long/hard
        to read and separate it out from the UI render method e.g. by using classNames package: https://dev.azure.com/secure-the-file/Application/_workitems/edit/14654 */}
      <div
        data-testid="checkmark-icon"
        className={`notification-popup-icon${
          messageType === "ERROR"
            ? " red-icon"
            : messageType === "CSVERROR"
            ? " red-icon"
            : messageType === "SUCCESS"
            ? " green-icon"
            : messageType === "NOTIFICATION"
            ? " notification-bell"
            : messageType === "PROGRESS"
            ? " progress"
            : messageType === "INFO"
            ? " info"
            : ""
        }`}
      >
        {messageType === "NOTIFICATION"
          ? NotificationIcon
          : messageType === "PROGRESS"
          ? ProgressIcon
          : messageType === "INFO"
          ? InfoIcon
          : messageType === "CSVERROR"
          ? FilledError
          : messageType !== "SUCCESS"
          ? errorIcon
          : CheckMarkIcon}
      </div>
      <div
        id="alert-info"
        className="notification-text"
        data-testid="notification-text"
      >
        {message}
      </div>
      {displayCloseButton && (
        <button
          className="closeButton"
          autoFocus={messageType === "ERROR"}
          onClick={closePopup}
          data-testid="cross-icon"
          title="Close"
        >
          {CrossIcon}
        </button>
      )}
    </div>
  )
}

export type NotificationPopUpProps = {
  displaySuccessOrErrorPopup: TDisplaySuccessOrErrorPopup
  closePopup: () => void
  onViewFileClick?: (file: IFile) => void
}

export const NotificationPopUpStatic: React.FC<NotificationPopUpProps> = ({
  displaySuccessOrErrorPopup,
  closePopup,
  onViewFileClick,
}) => {
  const ref = useRef(null)
  useOutsideAlerter(ref, closePopup)

  const msgs = displaySuccessOrErrorPopup.messages!

  return (
    <div className="notification-pop-up-container prevent-click" ref={ref}>
      <div className={`notification-pop-up`}>
        {displaySuccessOrErrorPopup.message &&
          displaySuccessOrErrorPopup.messageType && (
            <NotificationPopUpMessage
              messageType={displaySuccessOrErrorPopup.messageType}
              message={displaySuccessOrErrorPopup.message}
              closePopup={closePopup}
            />
          )}
        {msgs?.length > 0 && (
          <>
            {msgs.map((msg, i) => {
              if (msg.message && msg.messageType) {
                return (
                  <div key={i}>
                    <NotificationPopUpMessage
                      closePopup={closePopup}
                      messageType={msg.messageType}
                      message={msg.message}
                      displayCloseButton={i === 0}
                    />
                  </div>
                )
              }
              return null
            })}
          </>
        )}
      </div>
    </div>
  )
}

const hasNoErrorMsg = (
  displaySuccessOrErrorPopup?: TDisplaySuccessOrErrorPopup
) => {
  if (displaySuccessOrErrorPopup?.messageType === "SUCCESS") {
    return true
  }
  const msgs = displaySuccessOrErrorPopup?.messages!
  if (!msgs?.find((m) => m.messageType === "ERROR")) {
    return true
  }
  return false
}

export const NotificationPopUp = () => {
  const { dispatch } = useApplicationContext()
  const {
    toastState: { displaySuccessOrErrorPopup },
    dispatch: toastDispatch,
  } = useToastContext()
  const {
    userState: { currentUser },
  } = useUserContext()

  const navigate = useNavigate()

  const [removeNotificationPopupTimeout, setRemoveNotificationPopupTimeout] =
    useState(undefined as NodeJS.Timeout | undefined)

  useEffect(() => {
    const noErrMsg = hasNoErrorMsg(displaySuccessOrErrorPopup)
    let removeNotificationTimeout: ReturnType<typeof setTimeout>
    if (displaySuccessOrErrorPopup && noErrMsg) {
      removeNotificationTimeout = setTimeout(() => {
        toastDispatch(setDisplaySuccessOrErrorMessage(undefined))
        setRemoveNotificationPopupTimeout(undefined)
      }, SUCCESS_NOTIICATION_DISAPPEARS_AFTER_MS) //9s to allow for css animation as well
      setRemoveNotificationPopupTimeout(removeNotificationTimeout)
    }
    return () => {
      clearTimeout(removeNotificationTimeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastDispatch, displaySuccessOrErrorPopup])

  if (!displaySuccessOrErrorPopup) {
    return null
  }

  let isAdviser = false
  if (currentUser?.roles?.includes(UserRoles.Adviser)) {
    isAdviser = true
  }

  const openFile = (file: IFile) => {
    dispatch(setSelectedFile(file.id))
    dispatch(setSelectedFolder(file.parentId ?? ""))
    if (isAdviser === false) {
      navigate(PAGE_PATH.FolderPage)
    } else {
      navigate(PAGE_PATH.ClientsPage)
    }
  }

  return (
    <NotificationPopUpStatic
      displaySuccessOrErrorPopup={displaySuccessOrErrorPopup}
      closePopup={() => {
        if (removeNotificationPopupTimeout)
          clearTimeout(removeNotificationPopupTimeout)

        toastDispatch(setDisplaySuccessOrErrorMessage(undefined))
      }}
      onViewFileClick={openFile}
    />
  )
}

export default NotificationPopUp
