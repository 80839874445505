const NotificationError = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 31C24.9558 31 31 24.9558 31 17.5C31 10.0442 24.9558 4 17.5 4C10.0442 4 4 10.0442 4 17.5C4 24.9558 10.0442 31 17.5 31Z"
      fill="#D00F00"
    />
    <path
      d="M17.5029 11V18"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M18.9351 22.5C18.9351 23.2926 18.2926 23.9351 17.5 23.9351C16.7074 23.9351 16.0649 23.2926 16.0649 22.5C16.0649 21.7074 16.7074 21.0649 17.5 21.0649C18.2926 21.0649 18.9351 21.7074 18.9351 22.5Z"
      fill="white"
      stroke="white"
      strokeWidth="0.129837"
    />
  </svg>
)
export default NotificationError
