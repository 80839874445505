import dayjs from "dayjs"
import {
  FilterType,
  TFilterOption,
} from "../../component/modules/Filter/Filter"
import { DATE_OPTIONS_NAMES } from "../../component/modules/Filter/helpers"
import { PAGE_PATH } from "../../contexts/application/constants"

export const MaxFileNameLength = 100

export const Max2FACodeLength = 6

export const CoopCompanyName = "Co-op Legal Services"

export const isCurrentRouteDocumentLibraryPage =
  window.location.pathname === PAGE_PATH.AccountDocumentLibraryPage

export const DocumentRequestNameMaxLength = 50

export const dateOptionsInitial = [
  {
    name: "1 month",
    value: dayjs().subtract(1, "month").unix().toString(),
    checked: false,
    type: FilterType.DATE,
  },
  {
    name: "3 months",
    value: dayjs().subtract(3, "month").unix().toString(),
    checked: false,
    type: FilterType.DATE,
  },
  {
    name: "6 months",
    value: dayjs().subtract(6, "month").unix().toString(),
    checked: false,
    type: FilterType.DATE,
  },
  {
    name: "1 year",
    value: dayjs().subtract(1, "year").unix().toString(),
    checked: false,
    type: FilterType.DATE,
  },
  {
    name: "5 years",
    value: dayjs().subtract(5, "year").unix().toString(),
    checked: false,
    type: FilterType.DATE,
  },
  {
    name: "10 years",
    value: dayjs().subtract(10, "year").unix().toString(),
    checked: false,
    type: FilterType.DATE,
  },
  {
    name: DATE_OPTIONS_NAMES.CUSTOMDATERANGE,
    checked: false,
    value: dayjs().unix().toString(),
    type: FilterType.DATE,
  },
] as TFilterOption[]
