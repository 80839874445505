import "./LoaderCenteredOnTop.css"

import { FC, useEffect } from "react"

import { IFile } from "../../../api/api-client/api-types"
import { setUploadingState } from "../../../contexts/application/action"
import { useApplicationContext } from "../../../contexts/application/context"
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner"

interface LoaderInCenterOfElementProps {
  file: IFile
}

export const LoaderInCenterOfElement: FC<LoaderInCenterOfElementProps> = ({
  file,
}) => {
  const { dispatch } = useApplicationContext()
  useEffect(() => {
    if (!file.created) {
      dispatch(setUploadingState(true))
    } else {
      dispatch(setUploadingState(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file.created])

  return (
    <div className="loader-in-file-card">
      <LoadingSpinner
        color="var(--color-universal-secondary-c)"
        size="50px"
        thickness="5px"
      />
    </div>
  )
}

interface LoaderCenteredOnTopProps {
  color?: string
  secondaryColor?: string
  backgroundColor?: string
}
export const LoaderCenteredOnTop = ({
  color,
  secondaryColor,
  backgroundColor,
}: LoaderCenteredOnTopProps) => {
  return (
    <div
      className="loader-over-page"
      style={{ backgroundColor: backgroundColor }}
    >
      <LoadingSpinner
        color={color ?? "var(--color-universal-secondary-c)"}
        secondaryColor={secondaryColor}
        size="50px"
        thickness="5px"
      />
    </div>
  )
}
