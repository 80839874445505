import classNames from "classnames"
import React from "react"
import { LoadingSpinner } from "../../atoms/LoadingSpinner/LoadingSpinner"
import "./AlertMessage.css"
import AlertMessageItemList, {
  AlertMessageItem,
} from "./AlertMessageItemList/AlertMessageItemList"

export enum MessageType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

interface AlertMessageProps {
  className?: string
  messageType: MessageType
  title?: string
  message?: string
  alertItems?: AlertMessageItem[]
  shouldDisplayCloseButton?: boolean
  isBeingActioned?: boolean
  icon?: React.ReactNode
  children?: React.ReactNode
  hasLeftThickBorder?: boolean
}

const AlertMessage: React.FC<AlertMessageProps> = ({
  className,
  messageType,
  title,
  message,
  alertItems,
  shouldDisplayCloseButton,
  isBeingActioned,
  icon,
  children,
  hasLeftThickBorder = true,
}) => {
  const alertMessageClass = classNames(
    `alert-message alert-message--${messageType}`,
    className,
    !hasLeftThickBorder && "alert-message--has-no-left-thick-border",
    icon !== undefined && "alert-message--has-small-padding"
  )

  return (
    <div className={alertMessageClass} role="alert">
      {icon && <div className="alert-message__icon">{icon}</div>}
      {title && <h2 className="alert-message__title">{title}</h2>}
      <div className="alert-message__description">
        <p>{message}</p>
      </div>
      {children}
      {alertItems && alertItems.length > 0 ? (
        isBeingActioned ? (
          <div className="alert-message_loading-spinner">
            <LoadingSpinner size="30px" />
          </div>
        ) : (
          <AlertMessageItemList
            messageType={messageType}
            alertItems={alertItems}
            shouldDisplayButton={shouldDisplayCloseButton}
          />
        )
      ) : null}
    </div>
  )
}

export default AlertMessage
