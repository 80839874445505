import { ThemeNames } from "../../contexts/application/constants"

export const themesAvailableOn3TierSystem = [
  ThemeNames.ABRDN,
  ThemeNames.ABRDN_BALMORAL,
  ThemeNames.ABRDN_CAPITAL,
  ThemeNames.ABRDN_GIBBS,
  ThemeNames.ABRDN_LUDLOW,
  ThemeNames.ABRDN_SHERIDAN,
  ThemeNames.ABRDN_SUCCESSION,
  ThemeNames.ABRDN_VANILLA,
  "AMADEUS",
  "CO_OP",
  ThemeNames.LIFESTAGE,
  ThemeNames.SCHOFIELD_SWEENEY,
]
