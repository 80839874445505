import { IFile } from "../../api-client/api-types"
import { baseUrl, httpClient } from "../httpClient"

export const patchUploadFile = async ({
  fileId,
  formData,
}: {
  fileId: string
  formData: FormData
}): Promise<IFile> => {
  const url = new URL(`${baseUrl}/api/Node/upload/${fileId}`)

  const response = await httpClient(url.toString(), {
    method: "PATCH",
    data: formData,
  })

  return response.data
}

export const postUploadFileToFolder = async ({
  folderId,
  formData,
}: {
  folderId: string
  formData: FormData
}): Promise<Array<IFile>> => {
  const url = new URL(`${baseUrl}/api/Node/${folderId}/upload`)

  const response = await httpClient(url.toString(), {
    method: "POST",
    data: formData,
  })

  return response.data
}

export const putRenameUploadedFile = async ({
  fileId,
  name,
}: {
  fileId: string
  name: string
}): Promise<boolean> => {
  const url = new URL(`${baseUrl}/api/Node/file/${fileId}/rename`)

  const response = await httpClient(url.toString(), {
    method: "PUT",
    data: name,
  })

  return response.data
}

export const postUploadAndStitchPages = async ({
  targetFolderId,
  sourceFileIds,
}: {
  targetFolderId: string
  sourceFileIds: string[]
}): Promise<IFile> => {
  const url = new URL(`${baseUrl}/api/Node/pdfstitching`)

  const response = await httpClient(url.toString(), {
    method: "POST",
    data: {
      targetFolderId,
      sourceFileIds,
    },
  })

  return response.data
}

export const postApproveFile = async ({
  fileId,
}: {
  fileId: string
}): Promise<string> => {
  const url = new URL(`${baseUrl}/api/Node/approvefile/${fileId}`)

  const response = await httpClient(url.toString(), {
    method: "POST",
  })

  return response.status === 200 ? "success" : "error"
}

export const deleteFile = async ({
  fileId,
}: {
  fileId: string
}): Promise<string> => {
  const url = new URL(`${baseUrl}/api/Node/file/${fileId}`)

  const response = await httpClient(url.toString(), {
    method: "DELETE",
  })

  return response.data
}

export const getFileById = async ({
  fileId,
}: {
  fileId: string
}): Promise<IFile> => {
  const url = new URL(`${baseUrl}/api/Node/file/${fileId}/treenode`)

  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}
