import {
  DistributionProjectAnalyticsInfoDto,
  DistributionProjectInfoDto,
  FilesWithTagsDto,
  FlatFileNodeDto,
  Provider,
} from "legado-generated-api-client"
import {
  IContact,
  IFile,
  IFolder,
  INodeShare,
  INotification,
  IProviderStatus,
  IUser,
} from "../../api/api-client/api-types"

import { Industry } from "legado-generated-api-client/models"
import { ReactNode } from "react"
import { ApiConnection } from "../../api/api-client/common/ApiConnection"
import { TFilterOption } from "../../component/modules/Filter/Filter"
import { TThemeName } from "../../design-tokens/types"

export type TSearchResult = {
  expression: string
  folders: IFolder[]
  files: IFile[]
  tags: TSearchTagResult[]
  clients: IUser[]
}

export type TSearchTagResult = {
  tagName: string
  files: string[]
}

export type TLink = {
  name: string
  href: string
  iconName?: string
}

export type TMessageTypes =
  | "SUCCESS"
  | "ERROR"
  | "WARNING"
  | "NOTIFICATION"
  | "PROGRESS"
  | "INFO"
  | "CSVERROR"
export type TMessage = string | ReactNode
export type TDisplayMessage = {
  messageType: TMessageTypes
  message: TMessage
}
export const SUCCESS_NOTIICATION_DISAPPEARS_AFTER_MS = 9000
export interface IMessageBase {
  messageType?: TMessageTypes
  message?: string | ReactNode
  fileToReview?: IFile | null
}

export interface TDisplaySuccessOrErrorPopup extends IMessageBase {
  messages?: IMessageBase[]
}

export type TApplicationAction = {
  apiConnection?: ApiConnection
  bulkUploadedFiles?: IFile[]
  clients?: IUser[]
  displayMessage?: TDisplaySuccessOrErrorPopup
  // User
  currentUser?: IUser
  userId?: string
  // Files
  file?: IFile
  flatFileList: FlatFileNodeDto[]
  documentLibraryClientFiles: IFile[]
  fileId?: string
  files: IFile[]
  firstVisit: boolean
  usingStaticData?: boolean
  // folders
  folderId?: string
  folder?: IFolder
  folders?: IFolder[]
  folderName?: string
  isLoading?: boolean
  userDataLoading?: boolean
  authLoading?: boolean
  newFolder?: IFolder
  newResult?: TSearchResult
  searchedTag: string
  modalOpen?: boolean
  // Contacts
  deleteContactId?: string
  contact?: IContact
  contacts?: IContact[]
  contactsShares?: INodeShare[]
  selectedContactId?: string
  selectedTheme?: TThemeName
  type: string
  usersSharingWithMe: IUser[]
  suggestedFolders?: IFolder[]
  pinnedFoldersAndFiles?: IFolder
  pinnedFolderOrFile?: IFolder | IFile
  isPinned?: boolean
  isUploading?: boolean
  apiKey?: string
  hostUrl?: string
  notifications?: INotification[]
  industries: Industry[]
  providers: Provider[]
  selectedProvider?: Provider
  selectedIndustry?: Industry
  showConnectivityBanner: boolean
  savedOpenFolderId: string
  providerStatuses: IProviderStatus[]
  updatedStatus: IProviderStatus
  globalFilterOptions: TFilterOption[]
  filesWithTags: FilesWithTagsDto[]
  // Projects
  projects: DistributionProjectAnalyticsInfoDto[]
  selectedProject: DistributionProjectInfoDto
  projectAnalytics: DistributionProjectAnalyticsInfoDto
  organisationIdForAdviserSearch?: string
  showMenu: boolean
  showFooter: boolean
  analyticsRoute: boolean
  headAccountId?: string
  personId?: string
  timeoutState?: boolean
  hideHeader?: boolean
}

export interface IApplicationState {
  // clients
  clients?: IUser[]
  // current user
  currentUser?: IUser
  // success or error popup
  displaySuccessOrErrorPopup?: {
    messageType: TMessageTypes
    message: string
  }
  // utilities
  apiConnection?: ApiConnection
  isLoading?: boolean
  authLoading?: boolean
  userDataLoading?: boolean
  firstVisit: boolean
  modalOpen?: boolean
  apiKey?: string
  showConnectivityBanner: boolean
  showMenu: boolean
  showFooter: boolean
  searchResult?: TSearchResult
  analyticsRoute: boolean
  // dev stuff
  usingStaticData?: boolean
  // folders
  folders: IFolder[]
  selectedFolderId: string
  suggestedFolders?: IFolder[]
  savedOpenFolderId: string // might be used to expand and contract dropdowns for folders
  headAccountId?: string
  personId?: string
  // contacts
  contacts: IContact[]
  contactsShares?: INodeShare[]
  selectedContactId: string
  // theme
  selectedTheme?: string
  // sharing with me
  selectedSharingWithMeUserId: string
  sharedWithMeFolders?: IFolder[]
  usersSharingWithMe: IUser[]
  // projects
  projects: DistributionProjectAnalyticsInfoDto[]
  projectAnalytics: DistributionProjectAnalyticsInfoDto
  selectedProject?: DistributionProjectInfoDto
  organisationIdForAdviserSearch?: string
  // Industry
  industries: Industry[]
  selectedIndustry?: Industry
  // Provider
  providers: Provider[]
  providerStatuses: IProviderStatus[]
  selectedProvider?: Provider
  // files
  selectedFileId?: string
  pendingFiles?: IFile[]
  pinnedFoldersAndFiles?: IFolder
  isUploading?: boolean
  filesWithTags: FilesWithTagsDto[]
  flatFileList: FlatFileNodeDto[]
  documentLibraryClientFiles: IFile[]
  // notifications
  notifications?: INotification[]

  // unknown
  searchedTag: string
  hostUrl?: string // API Controller?
  globalFilterOptions: TFilterOption[] // Maybe we can refactr this out?
  timeoutState?: boolean
  hideHeader?: boolean
  searchExpression?: string
}

export type TBackendContactRelationship =
  | "None"
  | "Accountant"
  | "Professional"
  | "Solicitor"
  | "FamilyMember"
  | "Friend"
  | "SpousePartner"
  | "Other"

export enum UserModuleType {
  WELCOME_SCREEN = "WelcomeScreen",
  VAULT = "Vault",
  AUDIT_LOG = "AuditLog",
  UPLOAD = "Upload",
  PERSONALISE_VAULT = "PersonaliseVault",
  SHARING = "Sharing",
  CONTACTS = "Contacts",
  SHARED_WITH_ME_LIST = "SharedWithMeList",
  SHARED_WITH_ME_VIEW = "SharedWithMeView",
  SHARED_WITH_ME_EDIT = "SharedWithMeEdit",
  HIDE_ORGANISATION_FROM_SHARING = "HideOrganisationFromSharing",
  DATA_EXTRACTION_UPLOAD = "DataExtractionUpload",
  LEFT_NAV = "LeftNav",
  SEARCH = "Search",
  DATES = "Dates",
  REMINDERS = "Reminders",
  TAGGING = "Tagging",
  NOTIFICATIONS = "Notifications",
  THUMBNAILS = "Thumbnail",
  PREVIEW = "Preview",
  CONNECTIVITY = "Connectivity",
  SCRAPING = "Scraping",
  CLIENT_INVITE = "ClientInvite",
  DOCUMENT_HUB = "DocumentHub",
  DOCUMENTS_LIBRARY_ADVISER_VIEW = "DocumentsLibraryAdviserView",
  DOCUMENT_LIBRARY = "DocumentLibrary",
  ORGANISATIONS_ADMIN = "OrganisationsAdmin",
  DOCUMENT_COMMENTING = "DocumentCommenting",
  CLIENT_SEARCH_IN_DOC_HUB = "ClientSearchInDocHub",
  QUICK_ACCESS = "QuickAccess",
  CLIENT_LIST = "ClientList",
  CANCEL_SUBSCRIPTION = "CancelSubscription",
  DOCUMENT_LIBRARY_NOTIFICATION_BUTTON = "DocumentLibraryNotificationButton",
  DOCUMENT_LIBRARY_VISIBILITY = "DocumentLibraryVisibility",
  CASE_MANAGER_DOCUMENT_EXCHANGE = "CaseManagerDocumentExchange",
  CASE_TRACKER = "CaseTracker",
  CASE_TRACKER_ANALYTICS = "CaseTrackerAnalytics",
  CASE_TRACKER_DOCUMENT_UPLOAD_FOR_SIGNING = "CaseTrackerDocumentUploadForSigning",
  PHONE_NUMBER_REQUIRED_FOR_CONTACT_INVITE = "PhoneNumberRequiredForContactInvite",
  CHANGE_PHONE_NUMBER = "ChangePhoneNumber",
  VAULT_DOCUMENT_UPLOAD_FOR_SIGNING = "VaultDocumentUploadForSigning",
  RENAME_FILE = "Rename",
  DELETE_FILE = "Delete",
  FLAT_FILE_LIST = "FlatFileList",
  DOCUMENT_REQUEST = "DocumentRequest",
}

export const PAGE_PATH = {
  HomePage: "/",
  FolderPage: "/my-files",
  SharingPage: "/sharing",
  SharedWithMePage: "/sharedWithMe",
  SearchResultPage: "/search-result",
  RecentActivityPage: "/All-Activity",
  ThemePage: "/theme",
  ErrorPage: "/error",
  ActivityFeed: "/activity-feed",
  NotificationsPage: "/notifications",
  ConnectivityPage: "/connectivity",
  ProfilePage: "/profile",
  DocusignRequestPage: "/docusign-request",
  DocusignRequestIframePage: "/docusign-request-iframe",
  DocusignRequestSentPage: "/docusign-request-sent",
  DocuSignAddSignature: "/docusign-add-signature/:fileId",
  Logout: "/logout",
  LoginPage: "/login",
  //this comes from back end and is used for setting the password
  //it also comes combined with the "key" param in the page as the authentication code
  RegisterPage: "/authentication",
  ResetPasswordPage: "/reset-password",
  PrimerPage: "/primer",
  ClientsPage: "/clients",
  AccountDocumentLibraryPage: "/document-library",
  CommunicationsListPage: "/communications-list",
  CommunicationsSearchPage: "/client-search",
  DocumentHubPage: "/document-hub",
  AnalyticsPage: "/analytics",
  IndividualClientPage: "/client",
  Organisations: "/organisations",
  OrganisationClients: "/organisation/clients",

  // personal representative pages
  CaseProfilePage: "/:workflowId/:workflowVersion/case/:caseId/profile",
  CaseNotificationsPage:
    "/:workflowId/:workflowVersion/case/:caseId/notifications",
  Cases: "/cases",
  CaseDocuments: "/:workflowId/:workflowVersion/case/:caseId/documents",
  CaseDocumentDetailsPage:
    "/:workflowId/:workflowVersion/case/:caseId/stage/:stageId/request/:requestId/document/:documentId",
  CaseDetailsPage: "/:workflowId/:workflowVersion/case/:caseId",
  CaseDocumentAddSignature:
    "/:workflowId/:workflowVersion/case/:caseId/stage/:stage/request/:requestId/document/:documentId/:fileId/add-signature",
  CaseSharingPage: "/:workflowId/:workflowVersion/case/:caseId/sharing",
  CaseSharedWithMePage:
    "/:workflowId/:workflowVersion/case/:caseId/shared-with-me",
  CaseSharedWithMeFileDetailsPage:
    "/:workflowId/:workflowVersion/case/:caseId/shared-with-me-file-details/:fileId",
  CaseSearchPage: "/:workflowId/:workflowVersion/case/:caseId/search",

  // case handler pages
  CaseManagerSSOPage: "/manager/sso",
  CaseManagerHomePage: "/manager",
  CaseManagerDocumentRequestPage:
    "/:workflowId/:workflowVersion/:apiKey/:user/manager/case/:caseId/document-request/case-external/:externalId",
  CaseManagerDocumentTaggerIframePage: "/manager/case/document-tagger-iframe",
  CaseManagerDocumentSentPage:
    "/manager/case/:caseId/document-sent/case-external/:externalId",
  CaseManagerAnalyticsPage: "/manager/:workflowId/analytics",
  CaseManagerLogout: "/manager/logout",

  // contact pages
  CaseContactSharedWithMePage: "/case-contact-shared-with-me",
  CaseContactFileDetailsPage: "/contact-file-details/:fileId",

  // error pages
  UnauthorisedPage: "/unauthorised",
  // TODO: Change this to be docusign-redirect in the front end and backend
  // https://dev.azure.com/secure-the-file/Application/_workitems/edit/16550
  DocusignRedirect: "/handleredirect",
  DocumentRequestPage: "/document-request",
  FileDetailsPage: "/file-details/:fileId",
}

export const providerStatusCodes: { [key: string]: string } = {
  0: "Connecting",
  1: "Unsuccessful",
  2: "Connected",
  3: "Disconnected",
  4: "Disabled",
  5: "Removed",
}

export const LEGADO_REMINDER_APPLICATION_ID = "LEGADO_REMINDER_APPLICATION_ID"

export const enum BulkDistributionVisibility {
  AdminOnly = "AdminOnly",
  AdviserOnly = "AdviserOnly",
  ClientOnly = "ClientOnly",
  ClientAndAdviser = "ClientAndAdviser",
}

export const enum UserRoles {
  PrimaryUser = "PrimaryUser",
  Contact = "Contact",
  Adviser = "Adviser",
  PowerAdviser = "PowerAdviser",
  Admin = "Admin",
  CorporateAdmin = "CorporateAdmin",
  LegadoAdmin = "LegadoAdmin",
}

export const ImportantDocumentNames = [
  "Consolidated Tax Certificate",
  "Pension Wake Up Letter",
  "Pension Savings Statement",
  "Lifetime Allowance Certificate",
  "Pension Annual Benefit Statement",
  "MPS Report",
  "Chargeable Event Certificate",
  "Pre-Age 75 Lifetime Allowance Declaration Request",
  "Age 75 Lifetime Allowance test Confirmation",
  "Drawdown Optional Review",
  "Regular Statement",
  "Crystallisation Limit Reached",
]

// used for the document library/ client search page (passed as a context value into the url string)
export const CommunicationsHeadAccountReferences = [
  { account: "AB-JULY-23", platform: "Quilter" },
  { account: "4460638", platform: "Quilter" },
  { account: "ac2600182", platform: "abrdn" },
  { account: "001-1000001", platform: "abrdn" },
  { account: "002-1000001", platform: "abrdn" },
  { account: "AC2000706-001", platform: "Quilter" },
  { account: "AC2000706-002", platform: "Quilter" },
  { account: "AD-JULY-23", platform: "Quilter" },
  { account: "AL-JULY-23", platform: "Quilter" },
  { account: "AN-JULY-23", platform: "Quilter" },
  { account: "AO-JULY-23", platform: "Quilter" },
  { account: "AP-JULY-23", platform: "Quilter" },
  { account: "AR-JULY-23", platform: "Quilter" },
  { account: "AW-JULY-23", platform: "Quilter" },
  { account: "AX-JULY-23", platform: "Quilter" },
  { account: "DM-JULY-23", platform: "Quilter" },
  { account: "FH-JULY-23", platform: "Quilter" },
  { account: "fsa-2399", platform: "Quilter" },
  { account: "isa-258", platform: "Quilter" },
  { account: "isa-5678", platform: "Quilter" },
  { account: "JB-JULY-23", platform: "Quilter" },
  { account: "jhp-2401", platform: "Quilter" },
  { account: "JV-JULY-23", platform: "Quilter" },
  { account: "largeclientsreference", platform: "Quilter" },
  { account: "MG-JULY-23", platform: "Quilter" },
  { account: "MP-JULY-23", platform: "Quilter" },
  { account: "nonelynone", platform: "Quilter" },
  { account: "pew", platform: "Quilter" },
  { account: "savings and investments", platform: "Quilter" },
  { account: "spice", platform: "Quilter" },
  { account: "SSIP-1000", platform: "Quilter" },
  { account: "SSIP-1001", platform: "Quilter" },
  { account: "SSIP-2000", platform: "Quilter" },
  { account: "SSIP-2011", platform: "Quilter" },
  { account: "SSIP-2014", platform: "Quilter" },
  { account: "SSIP-2020", platform: "Quilter" },
  { account: "SSIP-2021", platform: "Quilter" },
  { account: "SSIP-2023", platform: "Quilter" },
  { account: "SSIP-2033", platform: "Quilter" },
  { account: "SSIP-2044", platform: "Quilter" },
  { account: "SSIP-2055", platform: "Quilter" },
  { account: "SSIP-2063", platform: "Quilter" },
  { account: "SSIP-2077", platform: "Quilter" },
  { account: "SSIP-2087", platform: "Quilter" },
  { account: "SSIP-2089", platform: "Quilter" },
  { account: "staticdataguid", platform: "Quilter" },
  { account: "thisisaref", platform: "Quilter" },
]

export enum EMessageTypes {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARNING = "WARNING",
  NOTIFICATION = "NOTIFICATION",
  PROGRESS = "PROGRESS",
  INFO = "INFO",
  CSVERROR = "CSVERROR",
}
export enum PasswordSetupSteps {
  SetupPassword = "setupPassword",
  ResetPassword = "resetPassword",
  ConfirmIdentity = "confirmIdentity",
  ResetPasswordConfirmation = "resetPasswordConfirmation ",
}

export enum ActionType {
  Upload = "upload",
  Uploaded = "uploaded",
  CompleteAndUpload = "complete-upload",
  ReviewAndSign = "review-sign",
  ReviewAndComment = "review-comment",
  ReviewAndApprove = "review-approve",
  ReviewUploadAndSign = "review-upload-sign",
  ReviewDownloadSignAndPost = "review-download-sign-post",
  CompleteAndSign = "complete-sign",
}

export enum WorkflowCaseStatus {
  CLOSED = "closed",
}

export enum ThemeNames {
  ABRDN = "ABRDN",
  ABRDN_VANILLA = "ABRDN_VANILLA",
  ABRDN_BALMORAL = "ABRDN_BALMORAL",
  ABRDN_CAPITAL = "ABRDN_CAPITAL",
  ABRDN_LUDLOW = "ABRDN_LUDLOW",
  ABRDN_GIBBS = "ABRDN_GIBBS",
  ABRDN_SUCCESSION = "ABRDN_SUCCESSION",
  ABRDN_SHERIDAN = "ABRDN_SHERIDAN",
  QUILTER = "QUILTER",
  AMADEUS = "AMADEUS",
  LIFESTAGE = "LIFESTAGE",
  ONE_LEGACY = "ONE_LEGACY",
  THE_FAMILY_ELEPHANT = "THE_FAMILY_ELEPHANT",
  SCHOFIELD_SWEENEY = "SCHOFIELD_SWEENEY",
}

/**
 * Used mainly for DocumentLibrayPage where we deal with external documents
 */
export enum ExternalDocumentStatus {
  Complete = "Complete",
  Pending = "Pending",
  PendingDelayed = "PendingDelayed",
  Running = "Running",
  Failed = "Failed",
}

export enum FolderAccountTypes {
  HEAD = "Head",
  SUB = "Sub",
  CUSTOMER = "Customer",
}
