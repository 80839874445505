import React, { FC } from "react"

import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"
import { useApplicationContext } from "../../contexts/application/context"
import { MainLayout } from "./Main"

export const MainLayoutWrapper: FC<{
  children?: React.ReactNode
  basePath?: string
}> = ({ children, basePath }) => {
  const { pathname } = useLocation()
  const {
    applicationState: { selectedTheme },
  } = useApplicationContext()

  // set title to empty string if selectedTheme is undefined or empty
  const pageTitle =
    !selectedTheme || selectedTheme === ""
      ? ""
      : pathname === "/"
      ? "Document Vault"
      : pathname === "/my-files"
      ? "Files"
      : pathname === "/sharing"
      ? "Contacts"
      : pathname === "/sharedWithMe"
      ? "Shared"
      : pathname === "/search-result"
      ? "Search result"
      : pathname === "/All-Activity"
      ? "All activity"
      : pathname === "/notifications"
      ? "Notifications"
      : ""

  return (
    <MainLayout basePath={basePath}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {children}
    </MainLayout>
  )
}
