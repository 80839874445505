const NotificationWarning = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
  >
    <path
      d="M17.5 31C24.9558 31 31 24.9558 31 17.5C31 10.0442 24.9558 4 17.5 4C10.0442 4 4 10.0442 4 17.5C4 24.9558 10.0442 31 17.5 31Z"
      fill="#FFD964"
    />
    <mask id="path-3-inside-1_16524_23528" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5029 10V11V18V19H16.5029V18V11V10H18.5029Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5029 10V11V18V19H16.5029V18V11V10H18.5029Z"
      fill="var(--color-universal-secondary-b)"
    />
    <path
      d="M18.5029 10H18.6328V9.87016H18.5029V10ZM18.5029 19V19.1298H18.6328V19H18.5029ZM16.5029 19H16.3731V19.1298H16.5029V19ZM16.5029 10V9.87016H16.3731V10H16.5029ZM18.6328 11V10H18.3731V11H18.6328ZM18.6328 18V11H18.3731V18H18.6328ZM18.6328 19V18H18.3731V19H18.6328ZM16.5029 19.1298H18.5029V18.8702H16.5029V19.1298ZM16.3731 18V19H16.6328V18H16.3731ZM16.3731 11V18H16.6328V11H16.3731ZM16.3731 10V11H16.6328V10H16.3731ZM18.5029 9.87016H16.5029V10.1298H18.5029V9.87016Z"
      fill="var(--color-universal-secondary-b)"
      mask="url(#path-3-inside-1_16524_23528)"
    />
    <path
      d="M17.5029 21V22"
      stroke="var(--color-universal-secondary-b)"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
)
export default NotificationWarning
