import { IFolder, IFile } from "../../../api/api-client/api-types"
import { GetFileType } from "../../helpers/ResolveFunctions"
import { FilterType, TFilterOption } from "./Filter"
import { DATE_OPTIONS_NAMES } from "./helpers"
import { DateRangeData } from "../DatePicker/helpers"
import {
  ActivityLogEntryDto,
  FlatFileNodeDto,
} from "legado-generated-api-client"

export const filterActivities = (
  activities: ActivityLogEntryDto[],
  options: TFilterOption[]
) => {
  const passingActivities = [] as ActivityLogEntryDto[]
  activities.forEach((a) => {
    const res = filterNode(a, options)
    res && passingActivities.push(res as ActivityLogEntryDto)
  })
  return passingActivities
}

export const filterFiles = (
  initialFiles: IFile[],
  filterOptions: TFilterOption[],
  shared?: boolean
) => {
  const passingFiles = [] as IFile[]
  initialFiles.forEach((file) => {
    const res = filterNode(file, filterOptions, shared)
    res && passingFiles.push(res as IFile)
  })

  return passingFiles
}

export const filterFolderTree = (
  initialFolders: IFolder[],
  filterOptions: TFilterOption[],
  shared?: boolean
) => {
  let filteredFolders = [] as IFolder[]

  let folders = JSON.parse(JSON.stringify(initialFolders)) as IFolder[]

  folders.forEach((folder) => {
    if (folder.contents && folder.contents.length > 0) {
      folder.contents = folder.contents.filter((content) =>
        filterNode(content, filterOptions, shared)
      ) as IFile[]
    }

    if (folder.childFolders && folder.childFolders.length > 0) {
      folder.childFolders = filterFolderTree(
        folder.childFolders,
        filterOptions,
        shared
      )
    }
    //A parent will not be filtered if any of its kids pass the filters
    if (
      (!folder.contents || folder.contents?.length === 0) &&
      (!folder.childFolders || folder.childFolders?.length === 0)
    ) {
      folder = filterNode(folder, filterOptions, shared) as IFolder
    }

    if (folder) {
      filteredFolders.push(folder)
    }
  })
  return filteredFolders
}

export const filterNode = (
  node: IFolder | IFile | ActivityLogEntryDto | FlatFileNodeDto,
  filterOptions: TFilterOption[],
  shared?: boolean
) => {
  const optionTypes: string[] = []
  filterOptions.forEach(
    (o) => !optionTypes.includes(o.type) && optionTypes.push(o.type)
  )

  const allFilters = optionTypes.map((type) => {
    const typeOptions = filterOptions.filter((options) => options.type === type)
    const res = doesNodePassType(type, typeOptions, node, shared)
    return res
  })
  return allFilters.every((b) => b) ? node : null
}

const doesNodePassType = (
  type: string,
  typeOptions: TFilterOption[],
  node: IFolder | IFile | ActivityLogEntryDto | FlatFileNodeDto,
  shared?: boolean
) => {
  const checkedOptions = typeOptions.filter((t) => t.checked)

  if (
    checkedOptions.length === 0 &&
    (type === FilterType.DATE || type === FilterType.DATEYEAR)
  ) {
    return true
  }

  if (!checkedOptions || checkedOptions.length === 0) {
    return false
  }

  let res = false
  switch (type) {
    case "date":
      const sharedDate = (node as IFolder | IFile).dateShared
      const dateStr = !shared
        ? node.created
        : typeof sharedDate === "string"
        ? sharedDate
        : undefined
      const fTime = new Date(dateStr ?? Date.now()).getTime()
      const checkedName = checkedOptions[0].name
      if (
        checkedName === DATE_OPTIONS_NAMES.CUSTOMDATERANGE ||
        checkedName === DATE_OPTIONS_NAMES.LASTTAXYEAR ||
        checkedName === DATE_OPTIONS_NAMES.CURRENTTAXYEAR
      ) {
        res = isWithInDateRange(
          new Date(dateStr ?? Date.now()),
          checkedOptions[0].dateRange
        )
      } else {
        const date = parseInt(checkedOptions[0].value)
        if (fTime >= date) {
          res = true
        }
      }
      break
    case "dateyear":
      //Should accpept values only within the provided year range

      const year = new Date(parseInt(checkedOptions[0].value)).getFullYear()
      const fYear = new Date(node.created ?? "").getFullYear()
      if (fYear === year) {
        res = true
      }
      break
    case "status":
      checkedOptions.forEach((o) => {
        if (getNodeSharingPermission(node as IFile | IFolder) === o.value) {
          res = true
        }
      })
      break
    case "format":
      checkedOptions.forEach((o) => {
        const ext = (node as IFile).extension
        if (ext === undefined || GetFileType(ext) === o.value) {
          res = true
        }
      })
      break
    case "action":
      checkedOptions.forEach((o) => {
        const action = (node as ActivityLogEntryDto).action
        if (
          action &&
          o.value &&
          action.toLowerCase() === o.value.toLowerCase()
        ) {
          res = true
        }
      })
      break
    case "contact":
      checkedOptions.forEach((o) => {
        if ((node as ActivityLogEntryDto).actorName === o.value) {
          res = true
        }
      })
      break
    case "name":
      checkedOptions.forEach((o) => {
        const name = (node as IFile | IFolder).name
        if (
          (name && name.toLowerCase().includes(o.value.toLowerCase())) ||
          o.value === ""
        ) {
          res = true
        }
      })
      break
    case "folderName":
      checkedOptions.forEach((o) => {
        const name = (node as IFile).folderName
        if (
          (name && name.toLowerCase().includes(o.value.toLowerCase())) ||
          o.value === ""
        ) {
          res = true
        }
      })
      break
    case "fileName":
      checkedOptions.forEach((o) => {
        const name = (node as IFile).fileName
        if (
          (name && name.toLowerCase().includes(o.value.toLowerCase())) ||
          o.value === ""
        ) {
          res = true
        }
      })
      break
    default:
      checkedOptions.forEach((o) => {
        if (
          (node &&
            (node as any)[type] &&
            (node as any)[type]
              .toLowerCase()
              .includes(o.value.toLowerCase())) ||
          o.value === ""
        ) {
          res = true
        }
      })
  }
  return res
}

export const isWithInDateRange = (target?: Date, range?: DateRangeData) => {
  if (!range || !target) return false

  let fTime = new Date(target.toString()).getTime()
  const startDate = range.startDate?.getTime()
  const endDate = range.endDate?.getTime()
  if (startDate && endDate && fTime >= startDate && fTime <= endDate) {
    return true
  }
  return false
}

const getNodeSharingPermission = (node: IFolder | IFile) => {
  if (node.isEditable) return "Edit"
  else return "Read only"
}
