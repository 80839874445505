import { Lozenge } from "../Lozenge/Lozenge"
import "./DocumentOrFileStatusLozenge.css"

export enum DocumentOrFileStatus {
  Pending = "Pending",
  Signed = "Signed",
  Rejected = "Rejected",
}

export const DocumentOrFileStatusLozenge = ({ label }: { label: string }) => {
  const status = label as DocumentOrFileStatus
  switch (status) {
    case DocumentOrFileStatus.Signed:
      return <Lozenge className="green-lozenge" label={label} />

    case DocumentOrFileStatus.Pending:
      return <Lozenge className="grey-lozenge" label={label} />

    case DocumentOrFileStatus.Rejected:
      return <Lozenge className="red-lozenge" label={label} />

    default:
      return null
  }
}
