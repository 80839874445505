import "./GlobalStyle.css"

import { FC, ReactNode, useEffect } from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import {
  getAnimationVariables,
  getBreakpointVariables,
  getButtonVariables,
  getColorVariables,
  getIconSizingVariables,
  getOverlayVariables,
  getRadiusVariables,
  getShadowVariables,
  getTypefaceVariables,
  getTypographyVariables,
} from "./getters"
import {
  getBaseFont,
  getCustomStylesheet,
  isTheme,
} from "../design-tokens/getters"

import { CoopCompanyName } from "../utils/consts/consts"
import { Helmet } from "react-helmet-async"
import { TTheme } from "../design-tokens/types"
import { ThemeNames } from "../contexts/application/constants"
import { castToBool } from "../utils/helpers"
import { getComponentVariables } from "../design-system/tier3-component-specific-variables/componentVariables"
import { getTheme } from "../utils/theme/getTheme"
import { jamesHayImgs } from "../design-tokens/imgs/jamesHay"
import legado from "../design-tokens/themes/legado"
import { oneXImgs } from "../design-tokens/imgs/oneX"
import { scottishWidowsImgs } from "../design-tokens/imgs/scottishWidows"
import { setSelectedTheme } from "../contexts/application/action"
import { themesAvailableOn3TierSystem } from "../design-system/utils/constants"
import { useApplicationContext } from "../contexts/application/context"

export const GlobalStyles = createGlobalStyle`
${(props) => {
  const theme = isTheme(props.theme)
  if (!theme) {
    return ``
  }
  const imgs =
    theme.mode === "SCOTTISH_WIDOWS"
      ? scottishWidowsImgs
      : theme.mode === "JAMES_HAY"
      ? jamesHayImgs
      : oneXImgs
  const isMobile = navigator.maxTouchPoints > 1
  // The values from oneX are added as defaults to make it easier to find the variable for the use case
  return `
  :root {
    --backgroundFade-1: ${`rgba(255, 255, 255, 0.6)`};

    --bg-image-url-top-bar: ${` url(${imgs.topbarBackground})`};
    --max-page-height : ${isMobile ? "100vh" : "100%"};
    --max-page-width : ${isMobile ? "100vw" : "100%"};
    ${getAnimationVariables(theme)}
    ${getBreakpointVariables(theme)}
    ${getButtonVariables(theme)}
    ${getColorVariables(theme)}
    ${getIconSizingVariables(theme)}
    ${getOverlayVariables(theme)}
    ${getRadiusVariables(theme)}
    ${getShadowVariables(theme)}
    ${getTypefaceVariables(theme)}
    ${getTypographyVariables(theme)}
    ${
      themesAvailableOn3TierSystem.find(
        (themeItem) => themeItem === theme.mode
      ) && getComponentVariables(theme)
    }


  }
  `
}}
`
const themeClassMap = {
  LEGADO: "legado",
  ONE_X: "one-x document-hub-library",
  SCOTTISH_WIDOWS: "scottish-widows",
  MORAY_GROUP: "scottish-widows",
  CAPITA: "scottish-widows",
  BARCLAYS: "barclays",
  JAMES_HAY: "james-hay",
  AVIVA: "aviva",
  SUCCESSION: "succession",
  ABRDN: "abrdn document-hub-library",
  ABRDN_VANILLA: "abrdn-vanilla document-hub-library",
  ABRDN_BALMORAL: "abrdn-balmoral document-hub-library",
  ABRDN_CAPITAL: "abrdn-capital document-hub-library",
  ABRDN_LUDLOW: "abrdn-ludlow document-hub-library",
  ABRDN_GIBBS: "abrdn-gibbs document-hub-library",
  ABRDN_SUCCESSION: "abrdn-succession document-hub-library",
  ABRDN_SHERIDAN: "abrdn-sheridan document-hub-library",
  INTERACTIVE_INVESTOR: "interactive-investor",
  MONEYHUB: "moneyhub",
  MONEYHUB_EXL: "moneyhubExl",
  QUILTER: "quilter document-hub-library",
  THE_FAMILY_ELEPHANT: "the-family-elephant",
  FORT_CIRRUS: "fort-cirrus",
  CO_OP: "coop",
  ONE_LEGACY: "one-legacy",
  AMADEUS: "amadeus",
  LIFESTAGE: "lifestage",
  SCHOFIELD_SWEENEY: "schofield-sweeney",
}

export const GlobalStyle: FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const {
    applicationState: { selectedTheme },
  } = useApplicationContext()

  const theme = getTheme(selectedTheme ?? "")
  const shouldSetIconAndTheme =
    castToBool(process.env.REACT_APP_SET_FAVICON_AND_TITLE) ?? true

  if (shouldSetIconAndTheme) {
    setFaviconAndTitle(selectedTheme ?? "")
  }

  return (
    <ThemeProvider theme={theme}>
      <ThemeFontImport />
      <GlobalStyles />
      <ThemeStyles />
      <div className={`legado  ${themeClassMap[theme.mode]}`}>{children}</div>
    </ThemeProvider>
  )
}

const setFaviconAndTitle = (theme: string) => {
  const favicon = getFaviconLink(theme)
  const title = getTitle(theme)
  if (favicon) {
    const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement
    link.href = favicon
  }
  if (title) {
    document.title = title
  }
}

const getAbrdnTitle = (title: string) => {
  switch (title) {
    case "abrdn_balmoral":
      return "Balmoral"
    case "abrdn_capital":
      return "Capital"
    case "abrdn_ludlow":
      return "Ludlow"
    case "abrdn_gibbs":
      return " Gibbs Denley"
    case "abrdn_succession":
      return "Succession Wealth"
    case "abrdn_sheridan":
      return "Welcome to the Financial Planning and Advice"
    default:
      return "abrdn"
  }
}

const getTitle = (theme: string) => {
  let title = theme.toLowerCase()
  if (title === "co_op") {
    return `Document Exchange | ${CoopCompanyName}`
  }
  if (title.includes("abrdn")) {
    return (document.title = getAbrdnTitle(title))
  }
  title = title.charAt(0).toUpperCase() + title.slice(1)
  return title
}

const getFaviconLink = (theme: string) => {
  let hasFavicon = false
  switch (theme) {
    case ThemeNames.ABRDN:
    case ThemeNames.ABRDN_BALMORAL:
    case ThemeNames.ABRDN_CAPITAL:
    case ThemeNames.ABRDN_GIBBS:
    case ThemeNames.ABRDN_LUDLOW:
    case ThemeNames.ABRDN_SHERIDAN:
    case ThemeNames.ABRDN_SUCCESSION:
    case ThemeNames.ABRDN_VANILLA:
    case "AMADEUS":
    case "CO_OP":
    case "FORT_CIRRUS":
    case ThemeNames.LIFESTAGE:
    case ThemeNames.SCHOFIELD_SWEENEY:
    case "ONE_LEGACY":
    case "ONE_X":
    case "QUILTER":
    case "THE_FAMILY_ELEPHANT":
      hasFavicon = true
      break
    default:
      hasFavicon = false
  }
  return hasFavicon ? `/favicons/${theme}-favicon.ico` : ``
}

const getFontUrl = (theme: TTheme) => {
  const font = getBaseFont(theme)
  const fontUrl = font.url.value
  if (fontUrl && typeof fontUrl === "string") {
    return fontUrl
  }
}

export const ThemeFontImport = () => {
  const {
    applicationState: { selectedTheme },
  } = useApplicationContext()
  const url = getFontUrl(getTheme(selectedTheme ?? ""))
  return (
    <>
      {url && (
        <Helmet>
          <link rel="stylesheet" href={url} />
        </Helmet>
      )}
    </>
  )
}

export const ThemeStyles = () => {
  const {
    applicationState: { selectedTheme },
  } = useApplicationContext()
  const url = getCustomStylesheet(getTheme(selectedTheme ?? ""))
  return (
    <>
      {url && (
        <Helmet>
          <link rel="stylesheet" href={url} />
        </Helmet>
      )}
    </>
  )
}

export const GlobalStyleNoHelmet: FC<{
  children: ReactNode
  theme?: TTheme
}> = ({ children, theme }) => {
  const { dispatch } = useApplicationContext()

  const themeUsed = theme ?? legado

  //icons are not updating to in storybook when themes are changed
  //this resolves the issue by correctly setting the application context theme on storybook theme change
  useEffect(() => {
    console.log("themeUsed:", themeUsed.mode)
    dispatch(setSelectedTheme(themeUsed.mode))
  }, [dispatch, themeUsed.mode])

  return (
    <ThemeProvider theme={themeUsed}>
      <GlobalStyles />
      <div className={`legado  ${themeClassMap[themeUsed.mode]}`}>
        {children}
      </div>
    </ThemeProvider>
  )
}
