import { FC } from "react"

const IconChevronRight: FC = () => (
  <svg
    className="icon-chevron-right"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="IconChevronRight"
  >
    <path
      d="M10 4L21.7589 15.4347C21.8354 15.5089 21.896 15.597 21.9373 15.694C21.9787 15.791 22 15.895 22 16C22 16.105 21.9787 16.209 21.9373 16.306C21.896 16.403 21.8354 16.4911 21.7589 16.5653L10 28"
      stroke="var(--color-universal-secondary-b)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default IconChevronRight
